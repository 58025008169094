import Maps from 'assets/img/maps.png'
const SectionMaps = () => {
    return (
        <div className="bg-black h-screen w-full flex justify-center relative overflow-hidden">
            <img src={Maps} alt="maps" className="absolute bottom-0" style={{ right: '-50px' }} />
            <div className="container self-center grid grid-cols-2 gap-10 text-white">
                <div className="flex flex-col">
                    <h5 className="text-5xl">Where are we?</h5>
                    <div className="py-2"></div>
                    <span className="text-xl">
                        Yes, we are based in Indonesia. However, thanks to the technology, we are able to connect globally, Visit our office at OnePM Building, 2nd Floor, Gading Serpong Boulevard M5 No. 17-18, Curug Sangereng, Kelapa Dua, Tangerang, Banten 15810
                    </span>
                </div>
            </div>
        </div>
    )
}

export default SectionMaps