import Button from '@material-ui/core/Button'

const Stage7 = (props) => {
    const { data, setData, onSubmit, onBack } = props
    return (
        <div className="sm:px-2 md:p-0 px-2 block">
            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Apa saja hobby/ kegemaran saudara?</label>
                <input type="text" name="hobby" value={data.hobby} onChange={(e) => {
                    data.hobby = e.target.value
                    setData({...data})
                }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
            </div>

            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Apakah Saudara sedang menuggu hasil invterview dari perusahaan lain?</label>
                <div className="flex">
                    <label className="flex">
                        <input type="radio" name="is_waiting_for_other_company" value={true} className="my-auto mr-5" />
                        Ya
                    </label>
                    <div className="px-2"></div>
                    <label className="flex">
                        <input type="radio" name="is_waiting_for_other_company" value={false} className="my-auto mr-5" />
                        Tidak
                    </label>
                </div>
            </div>

            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Apakah Saudara pernah telibat dalam kasus tindak kejahatan? Jika ya, Jelaskan</label>
                <textarea name="crime_case" value={data.crime_case} onChange={(e) => {
                    data.crime_case = e.target.value
                    setData({...data})
                }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
            </div>

            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Cantumkan nama akun social media Saudara</label>
                <textarea name="social_media" value={data.social_media} onChange={(e) => {
                    data.social_media = e.target.value
                    setData({...data})
                }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
            </div>

            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Berapa gaji yang Saudara inginkan & fasiltas-fasilitas apa saja yang diperlukan selain gaji?</label>
                <textarea name="expected_salary" value={data.expected_salary} onChange={(e) => {
                    data.expected_salary = e.target.value
                    setData({...data})
                }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
            </div>

            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Jika Saudara diterima bekerja, Kapan Saudara dapat mulai bekerja?</label>
                <textarea name="start_working" value={data.start_working} onChange={(e) => {
                    data.start_working = e.target.value
                    setData({...data})
                }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
            </div>

            <div className="py-2"></div>

            <div className="flex w-full justify-center">
                <Button variant="outlined" color="primary" onClick={onBack}>Back</Button>
                <div className="px-2"></div>
                <Button variant="outlined" color="primary" onClick={onSubmit}>Lanjutkan</Button>
            </div>
        </div>
    )
}

export default Stage7