import Router from './router'
import { Provider } from 'react-redux'
import store from './redux'
import { useEffect, useMemo, useState } from 'react'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'

export default () => {
    const [darkMode, setDarkMode] = useState(false)

    const theme = useMemo(
        () => createMuiTheme({
            palette: {
                type: darkMode ? 'dark' : 'light',
                primary: {
                    main: window._env_.APP_PRIMARY_COLOR_MAIN,
                    light: window._env_.APP_PRIMARY_COLOR_LIGHT,
                    dark: window._env_.APP_PRIMARY_COLOR_DARK,
                },
            },
        }),
        [darkMode]
    )
    
    useEffect(() => {
        var root = document.querySelector(':root')
        root.style.setProperty('--primary', window._env_.APP_PRIMARY_COLOR_MAIN)
        root.style.setProperty('--primary-light', window._env_.APP_PRIMARY_COLOR_LIGHT)
    }, [])


    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <Router />
            </Provider>
        </ThemeProvider>
    )
}