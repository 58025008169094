import Button from '@material-ui/core/Button'
const Stage5 = (props) => {
    const { data, setData, onNext, onBack } = props
    return (
        <div className="sm:px-2 md:p-0 px-2 block">
            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Sebutkan 3 Kekuatan dan 3 Kelemahan</label>
                <textarea type="text" name="strength_weakness" value={data.strength_weakness} onChange={(e) => {
                    data.strength_weakness = e.target.value
                    setData({...data})
                }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
            </div>

            <div className="py-2"></div>

            <div className="flex w-full justify-center">
                <Button variant="outlined" color="primary" onClick={onBack}>Back</Button>
                <div className="px-2"></div>
                <Button variant="outlined" color="primary" onClick={onNext}>Next</Button>
            </div>
        </div>
    )
}

export default Stage5