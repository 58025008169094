import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button'
import { useEffect, useState } from 'react';


const StyledTableCell = withStyles((theme) => ({
    // head: {
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
    // },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const Stage3 = (props) => {
    const { data, setData, onNext, onBack } = props
    const [formalEducation, setFormalEducation] = useState([1])
    const [informalEducation, setInformalEducation] = useState([1])
    const [languageSkill, setLanguageSkill] = useState([1])
    const [computerSkill, setComputerSkill] = useState([1])

    const [prevInformalEducation, setPrevInformalEducation] = useState()
    const [prevFormalEducation, setPrevFormalEducation] = useState()
    const [prevLanguageSkill, setPrevLanguageSkill] = useState()
    const [prevComputerSkill, setPrevComputerSkill] = useState()

    useEffect(() => {
        if (data && data.formal_education) {
            setPrevFormalEducation(data.formal_education)
        }

        if (data && data.informal_education) {
            setPrevInformalEducation(data.informal_education)
        }

        if (data && data.language_skill) {
            setPrevLanguageSkill(data.language_skill)
        }

        if (data && data.computer_skill) {
            setPrevComputerSkill(data.computer_skill)
        }


    }, [data])


    const handleAdd = value => {
        var temp
        if (value === 'formal_education') {
            temp = [...formalEducation]
            temp.push(formalEducation.length)
            setFormalEducation(temp)
        } else if (value === 'informal_education') {
            temp = [...informalEducation]
            temp.push(informalEducation.length)
            setInformalEducation(temp)
        } else if (value === 'language_skill') {
            temp = [...languageSkill]
            temp.push(languageSkill.length)
            setLanguageSkill(temp)
        } else if (value === 'computer_skill') {
            temp = [...computerSkill]
            temp.push(computerSkill.length)
            setComputerSkill(temp)
        }
    }

    const handleAddPrev = value => {
        var temp
        if (value === 'formal_education') {
            temp = [...prevFormalEducation]
            temp.push({})
            setPrevFormalEducation(temp)
            data.formal_education = [...temp]
        } else if (value === 'informal_education') {
            temp = [...prevInformalEducation]
            temp.push({})
            setPrevInformalEducation(temp)
            data.informal_education = [...temp]
        } else if (value === 'language_skill') {
            temp = [...prevLanguageSkill]
            temp.push({})
            setPrevLanguageSkill(temp)
            data.language_skill = [...temp]
        } else if (value === 'computer_skill') {
            temp = [...prevComputerSkill]
            temp.push({})
            setPrevComputerSkill(temp)
            data.computer_skill = [...temp]
        }

        setData({...data})
    }



    return (
        <div className="sm:px-2 md:p-0 px-2 block">
            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Tingkat Pengalaman Pendidikan Formal</label>
                <TableContainer>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Tingkat Pendidikan</StyledTableCell>
                                <StyledTableCell align="left">Nama Sekolah</StyledTableCell>
                                <StyledTableCell align="left">Jurusan</StyledTableCell>
                                <StyledTableCell align="left">GPA</StyledTableCell>
                                <StyledTableCell align="left">Periode</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data && data.formal_education ? 
                                    prevFormalEducation?.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'formal_education[' + index + '][stage]'} value={item.stage} onChange={(e) => {
                                                        item.stage = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'formal_education[' + index + '][name]'} value={item.name} onChange={(e) => {
                                                        item.name = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'formal_education[' + index + '][major]'} value={item.major} onChange={(e) => {
                                                        item.major = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'formal_education[' + index + '][gpa]'} value={item.gpa} onChange={(e) => {
                                                        item.gpa = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'formal_education[' + index + '][period]'} value={item.period} onChange={(e) => {
                                                        item.period = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (prevFormalEducation.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={() => handleAddPrev('formal_education')}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    }) : 
                                    formalEducation.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'formal_education[' + index + '][stage]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'formal_education[' + index + '][name]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'formal_education[' + index + '][major]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'formal_education[' + index + '][gpa]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'formal_education[' + index + '][period]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (formalEducation.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={() => handleAdd('formal_education')}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Tingkat Pengalaman Pendidikan Informal</label>
                <TableContainer>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Nama</StyledTableCell>
                                <StyledTableCell align="left">Tempat</StyledTableCell>
                                <StyledTableCell align="left">Penyelenggara</StyledTableCell>
                                <StyledTableCell align="left">Periode</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data && data.informal_education ? 
                                    prevInformalEducation?.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'informal_education[' + index + '][name]'} value={item.name} onChange={(e) => {
                                                        item.name = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'informal_education[' + index + '][place]'} value={item.place} onChange={(e) => {
                                                        item.place = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'informal_education[' + index + '][organizer]'} value={item.organizer} onChange={(e) => {
                                                        item.organizer = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'informal_education[' + index + '][period]'} value={item.period} onChange={(e) => {
                                                        item.period = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (prevInformalEducation.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={() => handleAddPrev('informal_education')}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    }) : 
                                    informalEducation.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'informal_education[' + index + '][name]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'informal_education[' + index + '][place]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'informal_education[' + index + '][organizer]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'informal_education[' + index + '][period]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (informalEducation.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={() => handleAdd('informal_education')}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div className="py-2"></div>
            
            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Tuliskan Judul Karya Ilmiah Saudara</label>
                <input type="text" name="scientific_work" value={data.scientific_work} onChange={(e) => {
                    data.scientific_work = e.target.value
                    setData({...data})
                }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
            </div>

            <div className="py-2"></div>
            
            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Kemampuan Bahasa Asing (good, poor, excellent)</label>
                <TableContainer>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Bahasa</StyledTableCell>
                                <StyledTableCell align="left">Speaking</StyledTableCell>
                                <StyledTableCell align="left">Listening</StyledTableCell>
                                <StyledTableCell align="left">Writing</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data && data.language_skill ? 
                                    prevLanguageSkill?.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'language_skill[' + index + '][name]'} value={item.name} onChange={(e) => {
                                                        item.name = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'language_skill[' + index + '][speaking]'} value={item.speaking} onChange={(e) => {
                                                        item.speaking = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'language_skill[' + index + '][listening]'} value={item.listening} onChange={(e) => {
                                                        item.listening = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'language_skill[' + index + '][writing]'} value={item.writing} onChange={(e) => {
                                                        item.writing = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (prevLanguageSkill.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={() => handleAddPrev('language_skill')}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    }) : 
                                    languageSkill.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'language_skill[' + index + '][name]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'language_skill[' + index + '][speaking]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'language_skill[' + index + '][listening]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'language_skill[' + index + '][writing]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (languageSkill.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={() => handleAdd('language_skill')}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Aplikasi Komputer (good, poor, excellent)</label>
                <TableContainer>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Nama Software</StyledTableCell>
                                <StyledTableCell align="left">Tingkat Kemampuan</StyledTableCell>
                                <StyledTableCell align="left">Keterangan</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data && data.computer_skill ? 
                                    prevComputerSkill?.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'computer_skill[' + index + '][name]'} value={item.name} onChange={(e) => {
                                                        item.name = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'computer_skill[' + index + '][level]'} value={item.level} onChange={(e) => {
                                                        item.level = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'computer_skill[' + index + '][description]'} value={item.description} onChange={(e) => {
                                                        item.description = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (prevComputerSkill.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={() => handleAddPrev('computer_skill')}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    }) : 
                                    computerSkill.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'computer_skill[' + index + '][name]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'computer_skill[' + index + '][level]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'computer_skill[' + index + '][description]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (computerSkill.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={() => handleAdd('computer_skill')}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            
            
            <div className="py-2"></div>

            <div className="flex w-full justify-center">
                <Button variant="outlined" color="primary" onClick={onBack}>Back</Button>
                <div className="px-2"></div>
                <Button variant="outlined" color="primary" onClick={onNext}>Next</Button>
            </div>

        </div>
    )
}

export default Stage3