import useFetch from 'hooks/useFetch'
import { useEffect } from 'react'
import { SectionTop, SectionCarrer } from './components'
const Carrer = () => {
    const [fetchResponse, callBack] = useFetch()
    useEffect(() => {
        callBack({ url: '/analitycentries', method: 'POST', data: {
            url: '/career',
            media: 'web'
        } })
    }, [])
    return (
        <>
            <SectionTop />
            <SectionCarrer />
        </>
    )
}

export default Carrer