import Zoom from '@material-ui/core/Zoom';
import SuccessIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import { useHistory } from 'react-router';
const Success = (props) => {
    const {checked, data, closePopup} = props
    const history = useHistory()
    const handleClose = () => {
        closePopup()
        history.push('/home')
    }
    const handleApply = () => {
        closePopup()
        history.push('/applicant?job=' + data.title)
    }

    return (
        <Zoom in={checked}>
            <div className="fixed w-full h-full left-0 top-0 h-screen flex justify-center z-10" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
                <div className="self-center sm:w-full md:w-1/2 w-full bg-white rounded-md overflow-hidden">
                    <div className="p-7">
                        <div className="flex w-full justify-between">
                            <span className="text-xl text-primary my-auto">{data?.title}</span>
                            <IconButton onClick={() => closePopup()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                        <div className="py-2"></div>
                        <div>
                            <div dangerouslySetInnerHTML={{ __html: data?.description }}></div>
                        </div>
                    </div>
                    <div className="w-full bg-primary flex justify-center text-white py-3 cursor-pointer" onClick={handleApply}>APPLY FOR THIS JOB</div>
                    {/* <div className="flex justify-start">
                        <div className="bg-green-200 p-3 rounded-full">
                            <SuccessIcon className="text-green-600" style={{ fontSize: 50 }} />
                        </div>
                    </div>
                    <div className="py-3"></div>
                    <div className="block m-0">
                        <span className="font-bold text-gray-600 text-lg block">Terima kasih!</span>
                        <span className="text-gray-400 text-green-600">Lamaran anda akan segeran diproses, tunggu informasi lebih lanjut</span>
                    </div>
                    <div className="py-3"></div>
                    <div className="flex justify-end">
                        <Button variant="contained" color="primary" onClick={handleClose}>Kembali ke halaman utama</Button>
                    </div> */}
                </div>
            </div>
        </Zoom>
    )
}

export default Success