import axios from 'axios'
import getStageToUse from './stageConfig';

const stageGet = getStageToUse();
const BaseURL = stageGet.TO_BASEURL;

class BaseApi {
    api () {
        const baseApi = axios.create({
            baseURL: BaseURL
        })
        baseApi.interceptors.request.use((config) => {
            // config.headers.crossDomain = true
            // config.headers.Authorization = 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImIwMzZlNThiZTc1NzA0OTU4NzAwODQzMTkyNmQ1ZWU2YWEzZTA2YzFhOGIwYWQ3YjQzNWM3MjY4ZmM0Mzk5MWE3NTIzNGFmMmJkNjBiODZjIn0.eyJhdWQiOiI0IiwianRpIjoiYjAzNmU1OGJlNzU3MDQ5NTg3MDA4NDMxOTI2ZDVlZTZhYTNlMDZjMWE4YjBhZDdiNDM1YzcyNjhmYzQzOTkxYTc1MjM0YWYyYmQ2MGI4NmMiLCJpYXQiOjE2MDkxNDI3MjcsIm5iZiI6MTYwOTE0MjcyNywiZXhwIjoxNzY2OTA5MTI3LCJzdWIiOiI1YjJmMGRjMi1lOWVjLTRlZmItOTg3Ni1lNTkzMjkzNzJhNDYiLCJzY29wZXMiOltdLCJjb21wIjoiMDZiZWI5ODYtMmNhMC0xMWVhLTk0ZGMtMWE1ODJjZWFhYjA1In0.IW8zve6gArUGFCeg4kDf6tdz3-a5mJiYiR4UHsnSGbnBz0MsMbAHnQ5852yYf2fBpz16yKOHae8HINIod0MScUUoOzK5bbGrv7DyvQMBrfzQ-X9eg6rYtDmwHs7noFRUw_6uQb_3X64A747avAH3ywrBr0FELFsXII2RrMzwwNAT614fDsw19xqNPrYfJKERhMEcPOZyqKV0rKv8EpMi_JMTX-LjNjB6csyo8oyetianmSX4heQRgeFaWM17f7mT1kad6u3yUn6_RMpG-cFi-TXEGo4H526yA94vTcFvafUY72MB-pXK-I92JUCaRWL4DY9KaQfz8UkVG-kOxB5KQsINZiDIMP6LKJzSdHdyoTNjlieo6EfozCEK2xOmWv9HifYIJiUgn_SuhndVdi0dp7GZtTbAe9JDPNi8oJ_ZdsMLqmnaZgvpv8tan22jelIB1KW3rS4ofX9iOoCMchxAt_7Dtn40nVBdC1ReT1OEOv3rzkm9PHGmDxfJjb2iqOUsEuM-zEAnRsb22kbYFw1lGUoV2L1SSPd9fbuKXrE2crqR0BYz9IOATgCI7Y16yqEqGsQ3IHgupJH7i-VDcQWkYjT1H4xxBGp3x5iLp6DdwqQf49W_vW9lGNk-hfxT1xjDIbY4JN2XT_RaXPTqui-p28SwV_olEGtQQWjl8kj6E2E'
            return config
        }, function (error) {
            return Promise.reject(error)
        })
        baseApi.interceptors.response.use((response) => {
            return response.data ? response.data : response
        }, (error) => {
            const { response = {} } = error
            const { data } = response
            if (data) {
                return Promise.reject(data)
            }
            return Promise.reject(error)
        })
        return baseApi
    }
}

export default BaseApi