// import { inputter } from 'helpers';
var serialize = require('form-serialize')
function getData(id) {
    var form = document.querySelector('#' + id);
    var obj = serialize(form, { hash: true, disabled: true, empty: true });
    // obj.inputter = {
    //     ...inputter
    // }
    return obj
}

export default getData