import Landing from 'assets/img/Path 5@2x.png'
import LandingTop from 'assets/img/Path 4@2x.png'
import './styles.scss'

const SectionTop = () => {
    return (
        <div className="h-screen w-full flex justify-center relative overflow-hidden background-carrer">
            {/* <img src={LandingTop} className="absolute" width="85%" style={{ top: '-45%', left: '-22%' }} alt=""/>
            <img src={Landing} className="absolute" width="80%" style={{ bottom: '-80%', left: '-20%' }} alt=""/> */}
            <div className="self-center block text-center text-white z-1">
                {/* <h3 className="block text-6xl">Are you looking for a job?</h3> */}
                <h1 className="block sm:text-xl md:text-8xl text-xl">Are you looking for a job?</h1>
                <div className="pt-10"></div>
                <p className="sm:text-md md:text-xl text-md">
                    We have a lot of jobs opportunities and are hiring, <br/>
                    we’re always looking for high-passionated and talented candidates of all the business types, <br/>
                    use the form below and let us know about you and contact you back
                </p>
            </div>
        </div>
    )
}

export default SectionTop