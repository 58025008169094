import Button from '@material-ui/core/Button'
const Stage4 = (props) => {
    const { data, setData, onNext, onBack } = props
    return (
        <div className="block sm:px-2 md:p-0 px-2">
            <div className="sm:block md:flex block w-full">
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Nama Perusahaan (Saat ini / Terakhir bekerja)</label>
                    <input type="text" name="company" value={data.company} onChange={(e) => {
                        data.company = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>

                <div className="px-2"></div>

                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Bidang Perusahaan (Saat ini / Terakhir bekerja)</label>
                    <input type="text" name="company_field" value={data.company_field} onChange={(e) => {
                        data.company_field = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
            </div>

            <div className="py-2"></div>

            <div className="sm:block md:flex block w-full">
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Jabatan (Saat ini / Terakhir bekerja)</label>
                    <input type="text" name="position" value={data.position} onChange={(e) => {
                        data.position = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Lama Bekerja (Bulan / Tahun)</label>
                    <input type="text" name="length_of_work" value={data.length_of_work} onChange={(e) => {
                        data.length_of_work = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Jumlah Karyawan</label>
                    <input type="text" name="number_of_employees" value={data.number_of_employees} onChange={(e) => {
                        data.number_of_employees = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
            </div>

            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Tugas & Tanggung Jawab</label>
                <textarea name="job_responsibilities" value={data.job_responsibilities} onChange={(e) => {
                    data.job_responsibilities = e.target.value
                    setData({...data})
                }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
            </div>

            <div className="py-2"></div>
            
            <div className="sm:block md:flex block w-full">
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Gaji Pokok (Saat ini / Terakhir bekerja)</label>
                    <input type="text" name="salary" value={data.salary} onChange={(e) => {
                        data.salary = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Bonus / Insentif</label>
                    <input type="text" name="bonus" value={data.bonus} onChange={(e) => {
                        data.bonus = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
            </div>

            <div className="py-2"></div>
            
            <div className="sm:block md:flex block w-full">
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Tunjangan (Saat ini / Terakhir bekerja)</label>
                    <textarea type="text" name="allowance" value={data.allowance} onChange={(e) => {
                        data.allowance = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Alasan Berhenti</label>
                    <textarea type="text" name="quit_reasons" value={data.quit_reasons} onChange={(e) => {
                        data.quit_reasons = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
            </div>

            <div className="py-2"></div>

            <div className="flex w-full justify-center">
                <Button variant="outlined" color="primary" onClick={onBack}>Back</Button>
                <div className="px-2"></div>
                <Button variant="outlined" color="primary" onClick={onNext}>Next</Button>
            </div>
        </div>
    )
}

export default Stage4