import LogoBlack from "assets/img/Logo Minasa black.png"
import LogoWhite from "assets/img/Logo Minasa white.png"
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import Grow from '@material-ui/core/Grow'
import Slide from '@material-ui/core/Slide'
import { Collapse } from "@material-ui/core"
import { IconButton } from '@material-ui/core'
import Menu from '@material-ui/icons/Menu'
import Drawer from '@material-ui/core/Drawer'
import Sidebar from './Sidebar'

const Headers = () => {
    const [background, setBackground] = useState(false)
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const [menu, setMenu] = useState()
    const [show, setShow] = useState(true)
    const [scrollStatus, setScrollStatus] = useState({scrollDirection: null, scrollPos: 0,})
    const [direction, setDirection] = useState("up")


    const showHeader = () => {
        var top = window.pageYOffset
        setShow(top < window.innerHeight ? false : true)
    }

    useEffect(() => {
        showHeader()
        document.addEventListener('scroll', onScroll)
        return () => {
            document.removeEventListener('scroll', onscroll, false)
        }
    }, [])

    const onScroll = () => {
        showHeader()
        setScrollStatus((prev) => { // to get 'previous' value of state
            return {
                scrollDirection:
                    window.scrollY < prev.scrollPos
                        ? "up"
                        : "down",
                scrollPos: window.scrollY,
            }
        })
    }
    
    return (
        <Slide direction="down" in={scrollStatus?.scrollDirection === "down" ? false : show} timeout={500}>
            <div className={"fixed top-0 left-0 flex justify-center w-full py-2 z-10 duration-500 " + (scrollStatus?.scrollDirection === "up" && scrollStatus?.scrollPos >= 100 ? "bg-black text-white" : "")} onMouseLeave={() => setMenu()}>
                <div className="container flex justify-between">
                    <img src={scrollStatus?.scrollDirection === "up" && scrollStatus?.scrollPos >= 100 ? LogoWhite : LogoBlack} alt="minasa" width="150px" />
                    <div className="grid grid-cols-3 gap-5 my-auto w-1/4">
                        <div className="flex justify-end">
                            <div className="w-fit-content flex flex-col" onClick={() => history.push('/home')} onMouseEnter={() => setMenu('home')}>
                                <span className="text-lg">Home</span>
                                <div className={"border border-solid border-t-0 border-l-0 border-r-0 border-b-2 mx-auto duration-500 " + (menu === "home" ? "w-full " : "w-0 ") + (scrollStatus?.scrollDirection === "up" && scrollStatus?.scrollPos >= 100 ? "border-white" : "border-primary")}></div>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <div className="w-fit-content flex flex-col" onClick={() => history.push('/about')} onMouseEnter={() => setMenu('careers')}>
                                <span className="text-lg">About</span>
                                <div className={"border border-solid border-t-0 border-l-0 border-r-0 border-b-2 mx-auto duration-500 " + (menu === "careers" ? "w-full " : "w-0 ") + (scrollStatus?.scrollDirection === "up" && scrollStatus?.scrollPos >= 100 ? "border-white" : "border-primary")}></div>
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <div className="w-fit-content flex flex-col" onMouseEnter={() => setMenu('products')}>
                                <span className="text-lg">Careers</span>
                                <div className={"border border-solid border-t-0 border-l-0 border-r-0 border-b-2 mx-auto duration-500 " + (menu === "products" ? "w-full " : "w-0 ") + (scrollStatus?.scrollDirection === "up" && scrollStatus?.scrollPos >= 100 ? "border-white" : "border-primary")}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Slide>
    )
}

export default Headers