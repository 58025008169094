import Animated from 'assets/svg/Animated.svg'
import Animated2 from 'assets/svg/Animated2.svg'
import Animated3 from 'assets/svg/Animated3.svg'
import Animated4 from 'assets/svg/Animated4.svg'
import Button from '@material-ui/core/Button'
import Gallery1 from 'assets/img/Gallery1.jpeg'
import Gallery2 from 'assets/img/Gallery2.jpeg'
import Gallery3 from 'assets/img/Gallery3.jpeg'
import Gallery4 from 'assets/img/Gallery4.jpeg'
import Gallery5 from 'assets/img/Gallery5.jpeg'
import Gallery6 from 'assets/img/Gallery6.jpeg'
import Gallery7 from 'assets/img/Gallery7.jpeg'
import Gallery8 from 'assets/img/Gallery8.jpeg'
import Gallery9 from 'assets/img/Gallery9.jpeg'

import Vision from 'assets/gif/vision.gif'
import Mission from 'assets/gif/mission.gif'

const SectionVision = () => {
    return (
        <div className="w-full bg-black flex flex-col galaxy">
            <div className="container mx-auto text-white">
                <div className="flex flex-col text-center">
                    <h5 className="text-3xl">OUR VISION & MISSION</h5>
                    <div className="py-1"></div>
                    <span className="w-1/2 mx-auto">Look what we thought about our company's vision and mission</span>
                </div>
                {/* <div className="flex flex-col text-center">
                    <h5 className="text-3xl">OUR MISION</h5>
                    <div className="py-1"></div>
                    <span className="w-3/4 mx-auto">Provide solutions and innovative information technology services which is effective and efficient and has a positive impact on the Islamic financial industry</span>
                </div> */}
            </div>
            <div className="py-20"></div>
            <div className="w-full bg-white py-20 flex flex-col" style={{ borderTopLeftRadius: '100px', borderTopRightRadius: '100px' }}>
                <div className="container mx-auto relative text-center">
                    <div className="grid grid-cols-2 gap-20 relative left-0 w-3/5 mx-auto" style={{ top: "-200px" }}>
                        <div className="flex flex-col text-center">
                            <img src={Animated} alt="animasi" />
                            <h5 className="text-2xl">Our Vision</h5>
                            <span className="font-regular">Participate in developing the Indonesian Islamic finance industry to become the leading Islamic finance industry in the world</span>
                        </div>
                        <div className="flex flex-col text-center">
                            <img src={Animated2} alt="animasi" />
                            <h5 className="text-2xl">Our Mission</h5>
                            <span className="font-regular">Provide solutions and innovative information technology services which is effective and efficient and has a positive impact on the Islamic financial industry</span>
                        </div>
                        {/* <div className="flex flex-col text-center">
                            <img src={Animated3} alt="animasi" />
                            <h5 className="text-2xl">Inovasi</h5>
                            <span className="font-regular">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus praesentium eaque aliquid</span>
                        </div>
                        <div className="flex flex-col text-center">
                            <img src={Animated4} alt="animasi" />
                            <h5 className="text-2xl">Inovasi</h5>
                            <span className="font-regular">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus praesentium eaque aliquid</span>
                        </div> */}
                    </div>
                    {/* <h5 className="text-5xl">We Scale Like a Dream</h5>
                    <div className="py-5"></div>
                    <Button variant="contained" color="primary">Scale with us</Button>
                    <div className="py-12"></div>
                    <div className="grid grid-cols-4 gap-20">
                        <div className="flex flex-col text-right p-5 rounded-3xl relative" style={{ background: "rgba(124, 204, 108)", boxShadow: "0 0 transparent, 0 0 transparent, 0px 0px 32px 10px #b2e5b8" }}>
                            <div className="relative" style={{ top: "-100px" }}>
                                <img src="https://lelogama.go-jek.com/prime/upload/image/170_mil.png" width="100%" height="200px" alt="" />
                            </div>
                            <span className="text-3xl font-bold">190 million+</span>
                            <span className="font-regular">app download since 2015</span>
                        </div>
                        <div className="flex flex-col text-right p-5 rounded-3xl relative" style={{ background: "rgba(189, 122, 179)", boxShadow: "0 0 transparent, 0 0 transparent, 0px 0px 32px 10px #d0b4d4" }}>
                            <div className="relative" style={{ top: "-100px" }}>
                                <img src="https://lelogama.go-jek.com/prime/upload/image/2_million.png" width="100%" height="200px" alt="" />
                            </div>
                            <span className="text-3xl font-bold">190 million+</span>
                            <span className="font-regular">app download since 2015</span>
                        </div>
                        <div className="flex flex-col text-right p-5 rounded-3xl relative" style={{ background: "rgba(255, 143, 28)", boxShadow: "0 0 transparent, 0 0 transparent, 0px 0px 32px 10px #fac9a6" }}>
                            <div className="relative" style={{ top: "-100px" }}>
                                <img src="https://lelogama.go-jek.com/prime/upload/image/50_million.png" width="100%" height="200px" alt="" />
                            </div>
                            <span className="text-3xl font-bold">190 million+</span>
                            <span className="font-regular">app download since 2015</span>
                        </div>
                        <div className="flex flex-col text-right p-5 rounded-3xl relative" style={{ background: "rgba(74, 201, 227)", boxShadow: "0 0 transparent, 0 0 transparent, 0px 0px 32px 10px #b2e6f2" }}>
                            <div className="relative" style={{ top: "-100px" }}>
                                <img src="https://lelogama.go-jek.com/prime/upload/image/12x_growth.png" width="100%" height="200px" alt="" />
                            </div>
                            <span className="text-3xl font-bold">190 million+</span>
                            <span className="font-regular">app download since 2015</span>
                        </div>
                    </div> */}
                    <div className="flex flex-col">
                        <h5 className="text-5xl">We Scale Like a Dream</h5>
                        <div className="py-5"></div>
                        <div className="grid grid-cols-4 gap-4">
                            <div className="rounded-3xl overflow-hidden">
                                <img src={Gallery1} alt="" className='h-full' />
                            </div>
                            <div className="rounded-3xl overflow-hidden">
                                <img src={Gallery4} alt="" className='h-full' />
                            </div>
                            <div className="rounded-3xl overflow-hidden">
                                <img src={Gallery3} alt="" className='h-full' />
                            </div>
                            <div className="rounded-3xl overflow-hidden">
                                <img src={Gallery2} alt="" className='h-full' />
                            </div>
                            <div className="rounded-3xl overflow-hidden">
                                <img src={Gallery5} alt="" className='h-full' />
                            </div>
                            <div className="rounded-3xl overflow-hidden">
                                <img src={Gallery6} alt="" className='h-full' />
                            </div>
                            <div className="rounded-3xl col-span-2 row-span-2 overflow-hidden">
                                <img src={Gallery7} alt="" className='h-full' />
                            </div>
                            <div className="rounded-3xl overflow-hidden">
                                <img src={Gallery8} alt="" className='h-full' />
                            </div>
                            <div className="rounded-3xl overflow-hidden">
                                <img src={Gallery9} alt="" className='h-full' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionVision