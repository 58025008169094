
import useFetch from 'hooks/useFetch'
import { useEffect } from 'react'
import { SectionTop, SectionTeam, SectionStory, SectionMaps, SectionCoreValue } from './components'
export default () => {
    const [fetchResponse, callBack] = useFetch()

    useEffect(() => {
        callBack({ url: '/analitycentries', method: 'POST', data: {
            url: '/about',
            media: 'web'
        } })
    }, [])
    
    return (
        <>
            <SectionTop />
            <section id="team">
                <SectionTeam />
            </section>
            <SectionStory />
            <SectionCoreValue />
            <SectionMaps />
        </>
    )
}