import Button from '@material-ui/core/Button'

const Stage6 = (props) => {
    const { data, setData, onNext, onBack } = props
    return (
        <div className="sm:px-2 md:p-0 px-2 block">
            <div className="sm:block md:flex block w-full">
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Apakah Saudara merokok?</label>
                    <div className="flex">
                        <label className="flex">
                            <input type="radio" name="isSmoke" value={true} className="my-auto mr-5" />
                            Ya
                        </label>
                        <div className="px-2"></div>
                        <label className="flex">
                            <input type="radio" name="isSmoke" value={false} className="my-auto mr-5" />
                            Tidak, Saya tidak merokok
                        </label>
                    </div>
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Jika ya, Berapa batang /hari?</label>
                    <input type="text" name="cigarette_total" value={data.cigarette_total} onChange={(e) => {
                        data.cigarette_total = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
            </div>

            <div className="py-2"></div>

            
            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Apakah Saudara minum alkohol?</label>
                <div className="flex">
                    <label className="flex">
                        <input type="radio" name="isAlcohol" value={true} className="my-auto mr-5" />
                        Ya
                    </label>
                    <div className="px-2"></div>
                    <label className="flex">
                        <input type="radio" name="isAlcohol" value={false} className="my-auto mr-5" />
                        Tidak
                    </label>
                </div>
            </div>

            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Apakah Saudara pernah rawat inap 1 - 2 tahun terakhir? JIka ya, Jelaskan</label>
                <textarea name="treated" value={data.treated} onChange={(e) => {
                    data.treated = e.target.value
                    setData({...data})
                }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
            </div>

            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Apakah Saudara menggunakan kacamata/ softlens? Jika ya, Sebutkan Ukurannya</label>
                <textarea name="eyes_condition" value={data.eyes_condition} onChange={(e) => {
                    data.eyes_condition = e.target.value
                    setData({...data})
                }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
            </div>

            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Sebutkan nama referensi kerja saudara</label>
                <textarea name="reference" value={data.reference} onChange={(e) => {
                    data.reference = e.target.value
                    setData({...data})
                }} placeholder="Nama, Posisi, Nama Perusahaam, No telp/Email" className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
            </div>

            <div className="py-2"></div>

            <div className="flex w-full justify-center">
                <Button variant="outlined" color="primary" onClick={onBack}>Back</Button>
                <div className="px-2"></div>
                <Button variant="outlined" color="primary" onClick={onNext}>Next</Button>
            </div>
        </div>
    )
}

export default Stage6