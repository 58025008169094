import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button'
import { useEffect, useState } from 'react';


const StyledTableCell = withStyles((theme) => ({
    // head: {
    //   backgroundColor: theme.palette.common.black,
    //   color: theme.palette.common.white,
    // },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const Stage2 = (props) => {
    const { data, onNext, setData, onBack } = props
    const [familyMember, setFamilyMember] = useState([1])
    const [parentFamily, setParentFamily] = useState([1])
    const [prevFamilyMember, setPrevFamilyMember] = useState()
    const [prevParentFamily, setPrevParentFamily] = useState()
    const handleAddData = () => {
        var temp = [...familyMember]
        temp.push(familyMember.length)
        setFamilyMember(temp)
    }

    const handleAddDataParent = () => {
        var temp = [...parentFamily]
        temp.push(parentFamily.length)
        setParentFamily(temp)
    }

    useEffect(() => {
        if (data && data.family_member) {
            setPrevFamilyMember(data.family_member)
        }
        if (data && data.parent_family) {
            setPrevParentFamily(data.parent_family)
        }
    }, [data])

    const handleAddDataFamilyMember = () => {
        var temp = [...prevFamilyMember]
        temp.push({})
        setPrevFamilyMember(temp)
        data.family_member = [...temp]
        setData({...data})
    }

    const handleAddDataParentFamily = () => {
        var temp = [...prevParentFamily]
        temp.push({})
        setPrevParentFamily(temp)
        data.parent_family = [...temp]
        setData({...data})
    }

    return (
        <div className="sm:px-2 md:p-0 px-2 block">
            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Tuliskan Nama - Nama Anggota Keluarga Inti</label>
                <TableContainer>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Nama</StyledTableCell>
                                <StyledTableCell align="left">Hubungan</StyledTableCell>
                                <StyledTableCell align="left">Usia</StyledTableCell>
                                <StyledTableCell align="left">Pendidikan Terakhir</StyledTableCell>
                                <StyledTableCell align="left">Pekerjaan</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data && data.parent_family ? 
                                    prevParentFamily?.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'parent_family[' + index + '][name]'} value={item.name} onChange={(e) => {
                                                        item.name = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'parent_family[' + index + '][relation]'} value={item.relation} onChange={(e) => {
                                                        item.relation = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'parent_family[' + index + '][age]'} value={item.age} onChange={(e) => {
                                                        item.age = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'parent_family[' + index + '][last_education]'} value={item.last_education} onChange={(e) => {
                                                        item.last_education = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'parent_family[' + index + '][job]'} value={item.job} onChange={(e) => {
                                                        item.job = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (prevParentFamily.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={handleAddDataParentFamily}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    }) : 
                                    parentFamily.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'parent_family[' + index + '][name]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'parent_family[' + index + '][relation]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'parent_family[' + index + '][age]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'parent_family[' + index + '][last_education]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'parent_family[' + index + '][job]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (parentFamily.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={handleAddDataParent}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            
            <div className="py-2"></div>

            <div className="block w-full">
                <label className="block mb-2 text-gray-400">Jika Sudah Berkeluarga, Tuliskan Nama Suami, Istri, dan Anak</label>
                <TableContainer>
                    <Table aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Nama</StyledTableCell>
                                <StyledTableCell align="left">Hubungan</StyledTableCell>
                                <StyledTableCell align="left">Usia</StyledTableCell>
                                <StyledTableCell align="left">Pendidikan Terakhir</StyledTableCell>
                                <StyledTableCell align="left">Pekerjaan</StyledTableCell>
                                <StyledTableCell align="left">Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data && data.family_member ? 
                                    prevFamilyMember?.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'family_member[' + index + '][name]'} value={item.name} onChange={(e) => {
                                                        item.name = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'family_member[' + index + '][relation]'} value={item.relation} onChange={(e) => {
                                                        item.relation = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'family_member[' + index + '][age]'} value={item.age} onChange={(e) => {
                                                        item.age = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'family_member[' + index + '][last_education]'} value={item.last_education} onChange={(e) => {
                                                        item.last_education = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'family_member[' + index + '][job]'} value={item.job} onChange={(e) => {
                                                        item.job = e.target.value
                                                        setData({...data})
                                                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>

                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (prevFamilyMember.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={handleAddDataFamilyMember}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    }) : 
                                    familyMember.map((item, index) => {
                                        return (
                                            <StyledTableRow>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'family_member[' + index + '][name]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'family_member[' + index + '][relation]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'family_member[' + index + '][age]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'family_member[' + index + '][last_education]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    <input type="text" name={'family_member[' + index + '][job]'} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                                                </StyledTableCell>
                                                <StyledTableCell component="th" scope="row">
                                                    {
                                                        index === (familyMember.length - 1) ?
                                                            <Button variant="contained" color="primary" onClick={handleAddData}>Tambah Data</Button> : null
                                                    }
                                                </StyledTableCell>
                                            </StyledTableRow>
                                        )
                                    })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>

            <div className="py-2"></div>

            <div className="sm:block md:flex block w-full">
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Nama Kontak Darurat</label>
                    <input type="text" name="emergency_contact_name" value={data.emergency_contact_name} onChange={(e) => {
                        data.emergency_contact_name = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Hubungan</label>
                    <input type="text" name="emergency_contact_relation" value={data.emergency_contact_relation} onChange={(e) => {
                        data.emergency_contact_relation = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Nomor Kontak</label>
                    <input type="text" name="emergency_contact_number" value={data.emergency_contact_number} onChange={(e) => {
                        data.emergency_contact_number = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
            </div>

            <div className="py-2"></div>

            <div className="flex w-full justify-center">
                <Button variant="outlined" color="primary" onClick={onBack}>Back</Button>
                <div className="px-2"></div>
                <Button variant="outlined" color="primary" onClick={onNext}>Next</Button>
            </div>
        </div>
    )
}

export default Stage2