import Gallery1 from 'assets/img/Gallery1.jpeg'
import Gallery2 from 'assets/img/Gallery2.jpeg'
import Gallery3 from 'assets/img/Gallery3.jpeg'
import Gallery4 from 'assets/img/Gallery4.jpeg'
import Gallery5 from 'assets/img/Gallery5.jpeg'
import Gallery7 from 'assets/img/Gallery7.jpeg'
import Gallery8 from 'assets/img/Gallery8.jpeg'
import Gallery9 from 'assets/img/Gallery9.jpeg'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Autoplay } from 'swiper';
import Slide from '@material-ui/core/Slide';

import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import './styles.scss'
import { useState } from 'react'

SwiperCore.use([EffectFade, Autoplay]);
const SectionNews = () => {
    const [selectedHover, setSelectedHover] = useState()

    const data = [
        {
            image: Gallery1,
            text: 'Collaboration Kubon with Taniman (Tangsel)'
        },
        {
            image: Gallery2,
            text: 'Whoop! Jamu Ben Tuman as our 1st product in Koperasi Dana Daya Sejahtera. Grab it now!'
        },
        {
            image: Gallery3,
            text: 'Our 1st piloting Kubon with People in Dasana'
        },
        {
            image: Gallery4,
            text: 'Here we are, Minasa Team!'
        },
        {
            image: Gallery5,
            text: 'We, our business team. We create an innovation into reality!'
        },
        // {
        //     image: Gallery7,
        //     text: 'We, our technical team. Technology is our best partner!'
        // },
        {
            image: Gallery9,
            text: 'We, our technical team. Technology is our best partner!'
        }
    ]
    const handleOnMouseEnter = (el) => {
        console.log(el)
        setSelectedHover(el)
    }
    const handleOnAutoplay = () => {
        console.log('handle on autoplay')
    }
    const handleOnAutoplayStart = () => {
        console.log('handleOnAutoplayStart')
    }
    return (
        <div>
            <div className="flex justify-center p-5 sm:px-5 md:px-40 px-5">
                <span className="block text-2xl">Look what we have been doing so far</span>
            </div>
            <div className="sm:px-5 md:px-10 px-5 flex justify-center">
                <div className="w-full sm:hidden md:flex hidden">
                    <Swiper autoplay={true} slidesPerView={3} spaceBetween={25}>
                        {
                            data.map((i, el) => {
                                return (
                                    <SwiperSlide style={{ height: '500px' }}>
                                        <div className="w-full h-full relative rounded-2xl overflow-hidden" onMouseEnter={() => handleOnMouseEnter(el)}>
                                            <img src={i.image} className="w-full h-full object-cover" alt="" />
                                        </div>
                                        <Slide direction="up" in={el === selectedHover} mountOnEnter unmountOnExit>
                                            <div className="absolute left-0 top-0 w-full h-full p-3 flex flex-col justify-end rounded-2xl" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
                                                <span className="text-white font-bold mt-auto">{i.text}</span>
                                            </div>
                                        </Slide>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
                <div className="w-full sm:flex md:hidden flex">
                    <Swiper autoplay={true}>
                        {
                            data.map((i, el) => {
                                return (
                                    <SwiperSlide style={{ height: '500px' }}>
                                        <div className="w-full h-full relative rounded-2xl overflow-hidden" onMouseEnter={() => handleOnMouseEnter(el)}>
                                            <img src={i.image} className="w-full h-full object-cover" alt="" />
                                        </div>
                                        <Slide direction="up" in={el === selectedHover} mountOnEnter unmountOnExit>
                                            <div className="absolute left-0 top-0 w-full h-full p-3 flex flex-col justify-end rounded-2xl" style={{ background: 'rgba(0, 0, 0, 0.3)' }}>
                                                <span className="text-white font-bold mt-auto">{i.text}</span>
                                            </div>
                                        </Slide>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
                {/* <div className="flex">
                    <div className="w-3/5 p-2">
                        <img src={Gallery1} className="w-full h-full object-cover" alt=""/>
                    </div>
                    <div className="w-2/5 p-2">
                        <img src={Gallery2} className="w-full h-full object-cover" alt=""/>
                    </div>
                </div>
                <div className="flex">
                    <div className="w-2/3 p-2">
                        <img src={Gallery3} className="w-full h-full object-cover" alt=""/>
                    </div>
                    <div className="w-2/3 p-2">
                        <img src={Gallery4} className="w-full h-full object-cover" alt=""/>
                    </div>
                </div> */}
            </div>
        </div>
    )
}

export default SectionNews