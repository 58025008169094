import { useState } from "react"
import { useHistory } from "react-router-dom"

const TopHeader = () => {
    const [menu, setMenu] = useState()
    const history = useHistory()
    return (
        <div className="fixed w-full left-0 top-0 flex justify-center text-primary-light z-1">
            <div className="container pt-10">
                <div className="grid grid-cols-3 gap-5 mx-auto sm:w-3/4 md:w-1/4 w-3/4">
                    <div className="flex justify-center cursor-pointer" onClick={() => history.push('/home')}>
                        <div className="w-fit-content flex flex-col" onMouseEnter={() => setMenu('home')}>
                            <span className="sm:text-md md:text-lg text-md">Home</span>
                            <div className={"border border-solid border-primary-light border-t-0 border-l-0 border-r-0 border-b-2 mx-auto duration-500 " + (menu === "home" ? "w-full " : "w-0 ")}></div>
                        </div>
                    </div>
                    <div className="flex justify-center cursor-pointer" onClick={() => history.push('/about')}>
                        <div className="w-fit-content flex flex-col" onMouseEnter={() => setMenu('careers')}>
                            <span className="sm:text-md md:text-lg text-md">About</span>
                            <div className={"border border-solid border-primary-light border-t-0 border-l-0 border-r-0 border-b-2 mx-auto duration-500 " + (menu === "careers" ? "w-full " : "w-0 ")}></div>
                        </div>
                    </div>
                    <div className="flex justify-center cursor-pointer">
                        <div className="w-fit-content flex flex-col" onMouseEnter={() => setMenu('products')}>
                            <span className="sm:text-md md:text-lg text-md">Careers</span>
                            <div className={"border border-solid border-primary-light border-t-0 border-l-0 border-r-0 border-b-2 mx-auto duration-500 " + (menu === "products" ? "w-full " : "w-0 ")}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopHeader