const initialState = {
  todos: [],
  counter: 0
}

const todo = (state = initialState, action) => {
  switch (action.type) {
    case 'INCREMENT':
      return { counter: state.counter + 1 }
    default: return state
  }
}

export default todo