import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/font.css'
import 'assets/css/custom.css'
import 'assets/css/animation.css'
import 'assets/css/color.scss'
import './assets/css/output.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';
import BaseApi from './libs/baseApi'

const baseApi = new BaseApi()
global.$baseApi = baseApi.api()

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
