import { useRef, useState } from 'react'
import WakafProduct from 'assets/img/Wakaf 2.png'
import Rz from 'assets/img/RZ.png'
import Daya from 'assets/img/1Daya.png'
import KubonImage from 'assets/img/MTD0137@2x.png';
import DayaLogo from 'assets/img/1daya logo.png'
import Zakat from 'assets/img/1Zakat BG.png'
import KubonLogo from 'assets/img/Kubon Logo.jpg'
import MSBLogo from 'assets/img/LogoMSB.png'
import Fade from '@material-ui/core/Fade'
import Slide from '@material-ui/core/Slide'
import AppStore from 'assets/img/appstore.png'
import PlayStore from 'assets/img/playstore.png'
import { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import { KeyboardBackspace } from '@material-ui/icons';

const SectionProduct = () => {
    const [product, setProduct] = useState()
    const [show, setShow] = useState({prev: 0})

    useEffect(() => {
        document.addEventListener('scroll', onscroll)

        return () => {
            document.removeEventListener('scroll', onscroll, false)
        }
    }, [])

    const onscroll = () => {
        const sectionProduct = document.getElementById('product')
        const product1 = document.getElementById('product1')
        const product2 = document.getElementById('product2')
        const product3 = document.getElementById('product3')
        const product4 = document.getElementById('product4')
        setShow((old) => {
            console.log('a', old?.prev)
            console.log('aaa', window.scrollY >= product2?.offsetTop - 400)
            if (window.scrollY >= sectionProduct?.offsetTop - 300 && window.scrollY < product2?.offsetTop - 400) return {prev: 1}   
            if (window.scrollY >= product2?.offsetTop - 400 && window.scrollY < product3?.offsetTop - 400) return {prev: 2}
            if (window.scrollY >= product3?.offsetTop - 400 && window.scrollY < product4?.offsetTop - 400) return {prev: 3}
            if (window.scrollY >= product4?.offsetTop - 400) return {prev: 4}
        })
    }

    return (
        <div className="w-full bg-product">
            <div className="container mx-auto py-20">
                <div className="flex flex-col">
                    <h5 className="text-4xl w-1/4">Here are our Product & Services!</h5>
                    <div className="py-5"></div>
                    <div className="grid grid-cols-3 gap-24" id="product1">
                        <div className="col-span-2 relative">
                            <Slide in={show?.prev >= 1} direction="right" timeout={1200}>
                                <img src={WakafProduct} alt="wakaf-product" />
                            </Slide>
                        </div>
                        <div className="my-auto">
                            <Slide direction="left" in={show?.prev >= 1} timeout={1200}>
                                <div className="flex flex-col my-auto">
                                    <div className="custom-shadow bg-black p-1 flex justify-center w-fit-content rounded-xl">
                                        <img src={MSBLogo} alt="msb-logo" width="60px" />
                                    </div>
                                    <div className="py-4"></div>
                                    <h5 className="text-3xl w-fit-content cool-link">Minasa Social Banking</h5>
                                    <div className="py-2"></div>
                                    <span>
                                        Minasa Social Banking is a digital platform which provide various banking transactions between social groups.; 1Zakat, 1Wakaf, and 1Daya
                                    </span>
                                    <div className="py-2"></div>
                                    <div className="flex w-3/4 grid grid-cols-2 w-3/4 gap-4">
                                        <a href="" target="_blank">
                                            <img src={PlayStore} alt="playstore" className="object-contain" />
                                        </a>
                                        <a href="" target="_blank">
                                            <img src={AppStore} alt="appstore" className="object-contain" />
                                        </a>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <div className="py-10"></div>
                    <div className="grid grid-cols-3 gap-24" id="product2">
                        <div className="my-auto">
                            <Slide in={show?.prev >= 2} direction="right" timeout={1200}>
                                <div className="flex flex-col my-auto">
                                    <div className="custom-shadow bg-black p-1 flex justify-center w-fit-content rounded-xl">
                                        <img src={Zakat} alt="zakat-logo" width="60px" />
                                    </div>
                                    <div className="py-4"></div>
                                    <h5 className="text-3xl w-fit-content cool-link">1Zakat</h5>
                                    <div className="py-2"></div>
                                    <span>
                                        1Zakat, is a digital platform that eases people in every process involving zakat; 
                                        a digital platform in the form of applications that can be used to assist the Amil Zakat Institution (UPZ) or any institution 
                                        for distribution process of zakat and monitor the use of zakat by mustahik and distribute zakat transparently and on target which can 
                                        improve living standards mustahik through the use of appropriate zakat for priority/basic needs by using the concept of non-cash (cashless) 
                                    </span>
                                    <div className="py-2"></div>
                                    <div className="flex w-3/4 grid grid-cols-2 w-3/4 gap-4">
                                        <a href="" target="_blank">
                                            <img src={PlayStore} alt="playstore" className="object-contain" />
                                        </a>
                                        <a href="" target="_blank">
                                            <img src={AppStore} alt="appstore" className="object-contain" />
                                        </a>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className="col-span-2 relative">
                            <Slide in={show?.prev >= 2} timeout={1200} direction="left">
                                <img src={Rz} alt="zakat-product" />
                            </Slide>
                        </div>
                    </div>
                    <div className="py-10"></div>
                    <div className="grid grid-cols-3 gap-24" id="product3">
                        <div className="col-span-2 relative">
                            <Slide in={show?.prev >= 3} direction="right" timeout={700}>
                                <img src={Daya} alt="1Daya-product" className="w-full" />
                            </Slide>
                        </div>
                        <div className="my-auto">
                            <Slide in={show?.prev >= 3} direction="left" timeout={700}>
                                <div className="flex flex-col my-auto">
                                    <div className="custom-shadow bg-black p-1 flex justify-center w-fit-content rounded-xl">
                                        <img src={DayaLogo} alt="1daya-logo" width="60px" />
                                    </div>
                                    <div className="py-4"></div>
                                    <h5 className="text-3xl w-fit-content cool-link">1Daya</h5>
                                    <div className="py-2"></div>
                                    <span>
                                        1Daya, is a digital cooperative platform that used to help the processes that exist in the business of a cooperative both from the onboarding process, 
                                        funding, financing, and also SHU distribution
                                    </span>
                                    <div className="py-2"></div>
                                    <div className="flex w-3/4 grid grid-cols-2 w-3/4 gap-4">
                                        <a href="" target="_blank">
                                            <img src={PlayStore} alt="playstore" className="object-contain" />
                                        </a>
                                        <a href="" target="_blank">
                                            <img src={AppStore} alt="appstore" className="object-contain" />
                                        </a>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                    </div>
                    <div className="py-10"></div>
                    <div className="grid grid-cols-3 gap-24" id="product4">
                        <div className="my-auto">
                            <Slide in={show?.prev >= 4} direction="right" timeout={700}>
                                <div className="flex flex-col my-auto">
                                    <div className="custom-shadow overflow-hidden flex justify-center w-fit-content rounded-xl">
                                        <img src={KubonLogo} alt="kubon-logo" width="60px" />
                                    </div>
                                    <div className="py-4"></div>
                                    <h5 className="text-3xl w-fit-content cool-link">Kubon</h5>
                                    <div className="py-2"></div>
                                    <span>
                                        Gamified real farming experience, Provides all the details of the most convenient plants choices for every beginners to far, 
                                        the information on tools and materials that need to be used, the details of the farm from what to do, how to do, fun tips for every single step, 
                                        and tracking progress. The users can start a discussion whether is it to ask question regarding the farm or reporting their issue and harvest 
                                    </span>
                                    <div className="py-2"></div>
                                    <div className="flex w-3/4 grid grid-cols-2 w-3/4 gap-4">
                                        <a href="" target="_blank">
                                            <img src={PlayStore} alt="playstore" className="object-contain" />
                                        </a>
                                        <a href="" target="_blank">
                                            <img src={AppStore} alt="appstore" className="object-contain" />
                                        </a>
                                    </div>
                                </div>
                            </Slide>
                        </div>
                        <div className="col-span-2 relative">
                            <Slide in={show?.prev >= 4} direction="left" timeout={700}>
                                <img src={KubonImage} alt="kubon-product" />
                            </Slide>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const SectionProduct2 = () => {
    const [slide, setSlide] = useState(2)
    const [show, setShow] = useState()
    const swiperRef = useRef()

    
    const onSlideChange = (e) => {
        console.log(e?.activeIndex)
        setSlide(e?.activeIndex)
    }

    const handleSlide = (index) => {
        if (index === -1) index = 3
        else if (index === 4) index = 0
        swiperRef?.current?.swiper?.slideTo(index)
        setSlide(index)
    }

    return (
        <div className='w-full flex py-20 flex-col bg-black'>
            <h5 className="text-4xl w-1/4 text-white mx-auto text-center">Here are our solutions</h5>
            {/* <div className="py-1"></div>
            <span className="text-white mx-auto w-1/3 text-center">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum quidem accusamus fugiat quae debitis voluptatum aperiam aliquid id ratione, rerum minus dignissimos doloribus? Quos necessitatibus autem recusandae fuga. Deserunt, velit?</span> */}
            <div className="py-5"></div>
            <div className="grid grid-cols-7">
                <div></div>
                <div className="col-span-5 flex flex-col">
                    <Swiper className="w-full text-white my-auto" ref={swiperRef} activeIndex={slide} onSlideChange={onSlideChange} centeredSlides={true} slidesPerView={1}>
                        <SwiperSlide className='flex justify-center'>
                            <div className="grid grid-cols-3 gap-24 self-center" id="product1">
                                <div className="col-span-2 relative">
                                    <Slide in={true} direction="right" timeout={1200}>
                                        <img src={WakafProduct} alt="wakaf-product" />
                                    </Slide>
                                </div>
                                <div className="my-auto">
                                    <Slide direction="left" in={true} timeout={1200}>
                                        <div className="flex flex-col my-auto">
                                            <div className="custom-shadow bg-black p-1 flex justify-center w-fit-content rounded-xl">
                                                <img src={MSBLogo} alt="msb-logo" width="60px" />
                                            </div>
                                            <div className="py-4"></div>
                                            <h5 className="text-3xl w-fit-content">Minasa Social Banking</h5>
                                            <div className="py-2"></div>
                                            <span>
                                                Minasa Social Banking is a digital platform which provide various banking transactions between social groups.; 1Zakat, 1Wakaf, and 1Daya
                                            </span>
                                            <div className="py-2"></div>
                                            <div className="flex w-3/4 grid grid-cols-2 w-3/4 gap-4">
                                                <a href="https://play.google.com/store/apps/details?id=id.socialbanking" target="_blank">
                                                    <img src={PlayStore} alt="playstore" className="object-contain" />
                                                </a>
                                                <a href="https://apps.apple.com/nz/app/social-banking/id154204452" target="_blank">
                                                    <img src={AppStore} alt="appstore" className="object-contain" />
                                                </a>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className='flex justify-center'>
                            <div className="grid grid-cols-3 gap-24 self-center" id="product2">
                                <div className="col-span-2 relative">
                                    <Slide in={true} timeout={1200} direction="left">
                                        <img src={Rz} alt="zakat-product" />
                                    </Slide>
                                </div>
                                <div className="my-auto">
                                    <Slide in={true} direction="right" timeout={1200}>
                                        <div className="flex flex-col my-auto">
                                            <div className="custom-shadow bg-black p-1 flex justify-center w-fit-content rounded-xl">
                                                <img src={Zakat} alt="zakat-logo" width="60px" />
                                            </div>
                                            <div className="py-4"></div>
                                            <h5 className="text-3xl w-fit-content">1Zakat</h5>
                                            <div className="py-2"></div>
                                            <span>
                                                1Zakat, is a digital platform that eases people in every process involving zakat; 
                                                a digital platform in the form of applications that can be used to assist the Amil Zakat Institution (UPZ) or any institution 
                                                for distribution process of zakat and monitor the use of zakat by mustahik and distribute zakat transparently and on target which can 
                                                improve living standards mustahik through the use of appropriate zakat for priority/basic needs by using the concept of non-cash (cashless) 
                                            </span>
                                            <div className="py-2"></div>
                                            {/* <div className="flex w-3/4 grid grid-cols-2 w-3/4 gap-4">
                                                <a href="" target="_blank">
                                                    <img src={PlayStore} alt="playstore" className="object-contain" />
                                                </a>
                                                <a href="" target="_blank">
                                                    <img src={AppStore} alt="appstore" className="object-contain" />
                                                </a>
                                            </div> */}
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide className='flex justify-center'>
                            <div className="grid grid-cols-3 gap-24 self-center" id="product3">
                                <div className="col-span-2 relative">
                                    <Slide in={true} direction="right" timeout={700}>
                                        <img src={Daya} alt="1Daya-product" className="w-full" />
                                    </Slide>
                                </div>
                                <div className="my-auto">
                                    <Slide in={true} direction="left" timeout={700}>
                                        <div className="flex flex-col my-auto">
                                            <div className="custom-shadow bg-black p-1 flex justify-center w-fit-content rounded-xl">
                                                <img src={DayaLogo} alt="1daya-logo" width="60px" />
                                            </div>
                                            <div className="py-4"></div>
                                            <h5 className="text-3xl w-fit-content">1Daya</h5>
                                            <div className="py-2"></div>
                                            <span>
                                                1Daya, is a digital cooperative platform that used to help the processes that exist in the business of a cooperative both from the onboarding process, 
                                                funding, financing, and also SHU distribution
                                            </span>
                                            <div className="py-2"></div>
                                            <div className="flex w-3/4 grid grid-cols-2 w-3/4 gap-4">
                                                <a href="https://play.google.com/store/apps/details?id=id.minasa.satudaya" target="_blank">
                                                    <img src={PlayStore} alt="playstore" className="object-contain" />
                                                </a>
                                                <a href="https://apps.apple.com/id/app/dds-digital/id1568928400" target="_blank">
                                                    <img src={AppStore} alt="appstore" className="object-contain" />
                                                </a>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                        </SwiperSlide>
                        
                        <SwiperSlide className='flex justify-center'>
                            <div className="grid grid-cols-3 gap-24 self-center" id="product4">
                                <div className="col-span-2 relative">
                                    <Slide in={true} direction="left" timeout={700}>
                                        <img src={KubonImage} alt="kubon-product" />
                                    </Slide>
                                </div>
                                <div className="my-auto">
                                    <Slide in={true} direction="right" timeout={700}>
                                        <div className="flex flex-col my-auto">
                                            <div className="custom-shadow overflow-hidden flex justify-center w-fit-content rounded-xl">
                                                <img src={KubonLogo} alt="kubon-logo" width="60px" />
                                            </div>
                                            <div className="py-4"></div>
                                            <h5 className="text-3xl w-fit-content">Kubon</h5>
                                            <div className="py-2"></div>
                                            <span>
                                                Gamified real farming experience, Provides all the details of the most convenient plants choices for every beginners to far, 
                                                the information on tools and materials that need to be used, the details of the farm from what to do, how to do, fun tips for every single step, 
                                                and tracking progress. The users can start a discussion whether is it to ask question regarding the farm or reporting their issue and harvest 
                                            </span>
                                            <div className="py-2"></div>
                                            <div className="flex w-3/4 grid grid-cols-2 w-3/4 gap-4">
                                                <a href="https://play.google.com/store/apps/details?id=id.kubon" target="_blank">
                                                    <img src={PlayStore} alt="playstore" className="object-contain" />
                                                </a>
                                                <a href="https://apps.apple.com/id/app/kubon/id1452471631" target="_blank">
                                                    <img src={AppStore} alt="appstore" className="object-contain" />
                                                </a>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                    <div className="py-10"></div>
                    <div className="grid grid-cols-4 w-1/6 mr-auto">
                        <div className={"h-3 mx-auto transition-all duration-200 rounded-full bg-white " + (slide === 0 || slide === 5 ? "w-full" : "w-3")}></div>
                        <div className={"h-3 mx-auto transition-all duration-200 rounded-full bg-white " + (slide === 1 ? "w-full" : "w-3")}></div>
                        <div className={"h-3 mx-auto transition-all duration-200 rounded-full bg-white " + (slide === 2 ? "w-full" : "w-3")}></div>
                        <div className={"h-3 mx-auto transition-all duration-200 rounded-full bg-white " + (slide === 3 ? "w-full" : "w-3")}></div>
                    </div>
                </div>
                <div className="flex flex-col mx-auto justify-center">
                    <div className="rounded-full w-32 h-32 cursor-pointer text-black flex justify-center bg-white opacity-60 hover:opacity-100 duration-200" onClick={() => handleSlide(slide + 1)}>
                        <div className="self-center">
                            <KeyboardBackspace style={{ fontSize: '45px', transform: 'rotate(180deg)' }} />
                        </div>
                    </div>
                    <div className="py-5"></div>
                    <div className="rounded-full w-32 h-32 cursor-pointer text-black flex justify-center bg-white opacity-60 hover:opacity-100 duration-200" onClick={() => handleSlide(slide - 1)}>
                        <div className="self-center">
                            <KeyboardBackspace style={{ fontSize: '45px' }} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionProduct2