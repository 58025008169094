import React from 'react'
import Headers from './components/Headers'
import Footers from './components/Footers'
export default ({ children }) => {
    return (
        <div className="block m-0 full-layout">
            <Headers />
            <div className="content w-full">
                { children }
            </div>
            <Footers />
        </div>
    )
}
