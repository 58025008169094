import { useHistory } from "react-router-dom"

export default () => {
    const history = useHistory()

    function goToAbout () {
        console.log(history)
        console.log('goto about')
        history.push('/about')
    }

    return (
        <div className="h-screen w-full flex justify-center">
            <div className="w-1/4 justify-between self-center flex">
                <button onClick={goToAbout}>Dine In</button>
                <button onClick={goToAbout}>Take Out</button>
            </div>
        </div>
    )
}
