import LinkedIn from '@material-ui/icons/LinkedIn'
import { useHistory, useLocation } from 'react-router'
import LogoMinasaWhite from 'assets/img/Logo Minasa white.png'
import { Facebook, Twitter, Instagram } from '@material-ui/icons'
import { Divider } from '@material-ui/core'
const Footers = () => {
    const location = useLocation()
    const history = useHistory()

    return (
        <div className="flex justify-center bg-black p-10 py-16">
            <div className="container flex flex-col">
                <img src={LogoMinasaWhite} alt="minasa" width="200px" />
                <div className="py-5"></div>
                <div className="grid grid-cols-5 gap-10 text-white">
                    <div className="flex flex-col">
                        <h6 className="text-lg">Company</h6>
                        <div className="py-2"></div>
                        <span className="font-regular cursor-pointer" onClick={() => history.push('/home')}>Home</span>
                        <div className="py-2"></div>
                        <span className="font-regular cursor-pointer" onClick={() => history.push('/about')}>About</span>
                        {/* <div className="py-2"></div>
                        <span className="font-regular">Blog</span> */}
                    </div>
                    {/* <div className="flex flex-col">
                        <h6 className="text-lg">Join with us</h6>
                        <div className="py-2"></div>
                        <span className="font-regular">Driver Partners</span>
                        <div className="py-2"></div>
                        <span className="font-regular">Merchant Partners</span>
                    </div> */}
                    <div className="flex flex-col">
                        <h6 className="text-lg">Careers</h6>
                        <div className="py-2"></div>
                        <span className="font-regular">Student</span>
                        <div className="py-2"></div>
                        <span className="font-regular">Professional</span>
                    </div>
                    <div className="flex flex-col">
                        <h6 className="text-lg">Get in touch</h6>
                        <div className="py-2"></div>
                        <span className="font-regular">Help Center</span>
                        <div className="py-2"></div>
                        <span className="font-regular">Contact Us</span>
                    </div>
                    <div className="flex flex-col">
                        <h6 className="text-lg">Connect with us</h6>
                        <div className="py-2"></div>
                        <div className="grid grid-cols-4 gap-4">
                            <Facebook style={{ fontSize: '35px' }} />
                            <Twitter style={{ fontSize: '35px' }} />
                            <Instagram style={{ fontSize: '35px' }} />
                            <LinkedIn style={{ fontSize: '35px' }} />
                        </div>
                    </div>
                </div>
                <div className="py-10"></div>
                <div className="border border-solid w-full border-t-0 border-r-0 border-l-0 border-gray-600"></div>
                <div className="py-5"></div>
                <div className="grid grid-cols-3 gap-5 w-1/2 text-white">
                    <span className="underline font-regular">Privacy Policy</span>
                    <span className="underline font-regular">Term & Condition</span>
                    <span className="underline font-regular">Data Attribution</span>
                </div>
                <div className="py-4"></div>
                <span className="font-regular text-white">
                    © 2021 Minasa | PT Minasa Finteknologi Syariah
                </span>
            </div>
        </div>
    )
}

export default Footers