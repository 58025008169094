import Button from '@material-ui/core/Button'
const Stage1 = (props) => {
    const { data, setData, onNext } = props
    const religion = [
        {
            name: 'Islam',
            value: 'Islam'
        },
        {
            name: 'Kristen',
            value: 'Kristen',
        },
        {
            name: 'Katolik',
            value: 'Katolik'
        },
        {
            name: 'Budha',
            value: 'Budha'
        },
        {
            name: 'Hindu',
            value: 'Hindu'
        },
        {
            name: 'Lainnya',
            value: 'Lainnya'
        }
    ]
    return (
        <div className="block sm:px-2 md:p-0 px-2">
            <div className="sm:block md:flex block w-full">
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Nama Lengkap</label>
                    <input type="text" name="name" value={data.name} onChange={(e) => {
                        data.name = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Alamat Email</label>
                    <input type="email" name="email" value={data.email} onChange={(e) => {
                        data.email = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Agama</label>
                    <select name="religion" id="" value={data.religion} onChange={(e) => {
                        data.religion = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100">
                        {
                            religion.map(item => {
                                return <option value={item.value}>{item.name}</option>
                            })
                        }
                    </select>
                </div>
            </div>

            <div className="py-2"></div>

            <div className="sm:block md:flex block w-full">
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Jenis Kelamin</label>
                    <select name="gender" value={data.gender} onChange={(e) => {
                        data.gender = e.target.value
                        setData({...data})
                    }} id="" className="w-full p-2 border border-solid border-green-primary bg-blue-100">
                        <option value="male">Laki-Laki</option>
                        <option value="female">Perempuan</option>
                        <option value="lainnya">Lainnya</option>
                    </select>
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Tempat Lahir</label>
                    <input type="text" name="place_of_birth" value={data.place_of_birth} onChange={(e) => {
                        data.place_of_birth = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Tanggal Lahir</label>
                    <input type="date" name="birth_date" value={data.birth_date} onChange={(e) => {
                        data.birth_date = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
            </div>

            <div className="py-2"></div>

            <div className="sm:block md:flex block w-full">
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Alamat Tempat Tinggal</label>
                    <input type="text" name="address" value={data.address} onChange={(e) => {
                        data.address = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Kota</label>
                    <input type="text" name="city" value={data.city} onChange={(e) => {
                        data.city = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Kode Pos</label>
                    <input type="text" name="postal_code" value={data.postal_code} onChange={(e) => {
                        data.postal_code = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
            </div>

            <div className="py-2"></div>

            <div className="sm:block md:flex block w-full">
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Status Pernikahan</label>
                    <select name="marital_status" id="" value={data.marital_status} onChange={(e) => {
                        data.marital_status = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100">
                        <option value="single">Lajang</option>
                        <option value="married">Menikah</option>
                    </select>
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Nomor HP (WA)</label>
                    <input type="text" name="phone_number" value={data.phone_number} onChange={(e) => {
                        data.phone_number = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
                <div className="px-2"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400">Transportasi Sehari-hari</label>
                    <input type="text" name="daily_transportation" value={data.daily_transportation} onChange={(e) => {
                        data.daily_transportation = e.target.value
                        setData({...data})
                    }} className="w-full p-2 border border-solid border-green-primary bg-blue-100" />
                </div>
            </div>

            <div className="py-2"></div>

            <div className="flex w-full justify-center">
                <Button variant="outlined" color="primary" onClick={onNext}>Next</Button>
            </div>

        </div>
    )
}

export default Stage1