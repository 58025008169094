import useFetch from 'hooks/useFetch'
import { useEffect } from 'react'
import { useLocation } from 'react-router'
import { SectionTop, SectionCarrer, SectionPersonalInfo } from './components'

const Applicant = () => {
    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const job = query.get('job')
    const [fetchResponse, callBack] = useFetch()
    
    useEffect(() => {
        callBack({ url: '/analitycentries', method: 'POST', data: {
            url: '/applicant',
            media: 'web',
            additional: {
                query: job
            }
        } })
    }, [])

    return (
        <>
            <SectionTop />
            <SectionPersonalInfo />
        </>
    )
}

export default Applicant