import Grow from '@material-ui/core/Grow'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import OurJob from 'assets/img/OurValue.png'
import Fade from '@material-ui/core/Fade'
import YellowRocket from 'assets/img/yellow_rocket.png'
import Planet from 'assets/img/planet.png'
import TopHeader from './components/topHeader'
import { useState, useEffect } from 'react'

const SectionTop = () => {
    const [show, setShow] = useState(true)

    const showHeader = () => {
        console.log('show header', window.pageYOffset)
        var top = window.pageYOffset
        setShow(top < 200 ? true : false)
        console.log(top < 200 ? true : false)
    }

    useEffect(() => {
        showHeader()
        document.addEventListener('scroll', onScroll)
        return () => {
            document.removeEventListener('scroll', onscroll, false)
        }
    }, [])

    const onScroll = () => {
        showHeader()
    }

    const handleScrollSpy = () => {
        var work = document.getElementById('achievment')
        window.scrollTo({
            top: work.offsetTop,
            behavior: 'smooth'
        });
    }

    return (
        <div className="h-screen w-full flex justify-center relative overflow-hidden planet">
            {
                show ?
                    <Fade in={true} timeout={500}>
                        <TopHeader />
                    </Fade> : null
            }            
            <Fade in={true} timeout={500}>
                <img src={Planet} alt="planet" className='absolute left-0 bottom-0 w-full' />
            </Fade>
            <div className="container self-center text-white sm:h-3/5 md:h-1/2 h-3/5">
                <div className="flex flex-col m-auto text-center relative w-3/5 h-full">
                    <img src={YellowRocket} alt="yellow_rocket" className='absolute w-1/5 rocket' />
                    <div className="flex flex-col justify-between z-1 h-full">
                        <Slide direction="down" in={true} timeout={500} mountOnEnter unmountOnExit>
                            <div className="flex flex-col">
                                <h2 className="sm:text-2xl md:text-4xl lg:text-5xl">{('Building the future with tech,').toUpperCase()}</h2>
                                <div className="py-1"></div>
                                <h2 className="sm:text-2xl md:text-4xl lg:text-5xl">{('Connecting Ecosystem').toUpperCase()}</h2>
                                <div className="py-2"></div>
                                <span className="sm:text-2xl md:text-4xl lg:text-5xl light-italic">Change for better!</span>
                                <div className="sm:py-3 md:py-10 py-3"></div>
                                <Slide direction="up" in={true} timeout={700} mountOnEnter unmountOnExit>
                                    <div className="sm:w-full md:w-1/2 w-full text-center mx-auto text-primary-light">
                                        <h5 className='text-lg'>By holding digital strategy, design and technical expertise in perfect balance, we purposefully connect ecosystem through digital.</h5>
                                    </div>
                                </Slide>
                                <div className="py-5"></div>
                                <div className='sm:w-full md:w-1/3 lg:w-1/4 mx-auto rounded-full overflow-hidden'>
                                    <Button variant="contained" centerRipple fullWidth color="primary" onClick={handleScrollSpy}>
                                        <span>Explore more</span>
                                    </Button>
                                </div>
                            </div>
                        </Slide>
                    </div>
                </div>
            </div>
            {/* <Fade in={true} timeout={900}>
                <img src={Rocket} alt="our-job" className="w-1/2 absolute" style={{ bottom: '-5%', right: '-10%' }} />
            </Fade> */}
            {/* <img src={LandingTop} className="absolute" width="85%" style={{ top: '-45%', left: '-22%' }} alt=""/>
            <img src={Landing} className="absolute" width="80%" style={{ bottom: '-80%', left: '-20%' }} alt=""/>
            <div className="self-center block text-center text-white z-1">
                <h3 className="block sm:text-xl md:text-6xl text-xl typewriter w-full text-center">Welcome to</h3>
                <h1 className="block sm:text-xl md:text-8xl text-xl typewriter">Minasa Finteknologi Syariah</h1>
                <div className="pt-5"></div>
                <Grow in={true}  style={{ transitionDelay: '200ms' }}>
                    <p className="sm:text-md md:text-xl text-md italic">Building the future with tech. Connecting Ecosystem. Change for the better!</p>
                </Grow>
                <div className="pt-10"></div>
                <button className="font-bold bg-white shadow-lg p-2 px-5 text-black rounded-xl hover:bg-gray-200" onClick={handleScrollSpy}>Explore More!</button>
            </div> */}
        </div>
    )
}

export default SectionTop