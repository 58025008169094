function getStageToUse() {
    let STAGETOUSE = {};
  
    window._env_.APP_ENVIRONMENT === 'Production' ? 
        STAGETOUSE = {
            TO_BASEURL: "https://api-webminasa.soluix.ai"
        } :
            STAGETOUSE = {
                TO_BASEURL: "https://dev-apiminasaweb.soluix.ai/minasa"
            }

    return STAGETOUSE;
  }
  
  export default getStageToUse;
  
  
  