import { BrowserRouter as Router, Route, Redirect, Switch, HashRouter } from "react-router-dom";
import FullLayout from '../layouts/Full';
import MainLayout from '../layouts/Main';
import AppRoute from './appRoute'

import Login from '../apps/login'
import Home from 'apps/home'
import About from 'apps/about'
import Career from 'apps/career'
import Applicant from 'apps/applicant'
import PrivacyPolicy from 'apps/privacyPolicy'

export default () => {
    return (
        <Router>
            <Switch>
                <AppRoute path="/login" layout={FullLayout} component={Login} />
                <AppRoute path="/home" layout={FullLayout} component={Home} />
                <AppRoute path="/about" layout={FullLayout} component={About} />
                <AppRoute path="/career" layout={FullLayout} component={Career} />
                <AppRoute path="/applicant" layout={FullLayout} component={Applicant} />
                <AppRoute path="/dds/privacy-policy" layout={MainLayout} component={PrivacyPolicy} />
                <Redirect to="/home" from="/" />
            </Switch>
        </Router>
    )
}
