import MacroEconomy from 'assets/svg/MacroEconomy.svg'
import SocialBanking from 'assets/svg/SocialBanking.svg'
import Communities from 'assets/svg/Communities.svg'
import Digital from 'assets/svg/Digital.svg'
import Api from 'assets/svg/Api.svg'
import CoreBanking from 'assets/svg/CoreBanking.svg'

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Autoplay } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import Grow from '@material-ui/core/Grow'

const SectionBusiness = () => {
    const [slide, setSlide] = useState(2)
    const [show, setShow] = useState()
    const swiperRef = useRef()

    const onSlideChange = (e) => {
        console.log(e?.activeIndex)
        setSlide(e?.activeIndex)
    }

    const handleSlide = (index) => {
        swiperRef?.current?.swiper?.slideTo(index + 5, 1000)
        setSlide(index)   
    }

    useEffect(() => {
        document.addEventListener('scroll', onscroll)

        return () => {
            document.removeEventListener('scroll', onscroll, false)
        }
    }, [])

    const onscroll = () => {
        console.log(window.scrollY)
        const sectionBusiness = document.getElementById('business')
        if (window.scrollY >= sectionBusiness?.offsetTop) setShow(true)
    }


    return (
        <div className="flex justify-center w-full h-screen bg-black overflow-hidden galaxy">
            <div className="flex flex-col self-center w-full container h-full py-10 relative">
                <div className="text-center w-1/2 mx-auto">
                    <h5 className="text-3xl text-white">Our Business</h5>
                    <div className="py-2"></div>
                    <span className="text-white">The world is changing. That’s why we’ve designed Minasa better.</span>
                </div>
                <Swiper className="w-full text-white my-auto" style={{ transform: 'rotate(-8deg)', height: '450px', paddingTop: '30px', paddingBottom: '30px', marginTop: '7%' }} ref={swiperRef} activeIndex={slide} loop={true} onSlideChange={onSlideChange} centeredSlides={true} slidesPerView={5} spaceBetween={60}>
                    <SwiperSlide>
                        <Grow in={show} timeout={300}>
                            <div className={"flex flex-col rounded-2xl p-5 h-full duration-500 " + (slide === 0 || slide - 5 === 0 || slide - 5 === 5 ? "transform-scale-1.1" : "")} style={{ background: 'rgba(147, 50, 142)' }}>
                                <img src={MacroEconomy} alt="micro-economy" style={{ height: '100px' }} />
                                <div className="py-3"></div>
                                <h5 className="text-lg">Purposeful Growth for Economy-Social</h5>
                                <div className="py-2"></div>
                                <small className="text-md font-light">The spirit of self-development and increasing customer growth is achieved through the process of self-introduction and skill enhancement.</small>
                            </div>
                        </Grow>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Grow in={show} timeout={500}>
                            <div className={"flex flex-col rounded-2xl p-5 h-full duration-500 " + (slide === 1 || slide - 5 === 1 ? "transform-scale-1.1" : "")} style={{ background: 'rgba(223, 25, 149)' }}>
                                <img src={SocialBanking} alt="social-banking" style={{ height: '100px' }} />
                                <div className="py-3"></div>
                                <h5 className="text-lg">Collaboration</h5>
                                <div className="py-2"></div>
                                <small className="text-md font-light">Consolidated companies can use the potential of disruption and growth of startups for a better mutual outcome and produce win-win collaborations </small>
                            </div>
                        </Grow>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Grow in={show} timeout={700}>
                            <div className={"flex flex-col rounded-2xl p-5 h-full duration-500 " + (slide === 2 || slide - 5 === 2 ? "transform-scale-1.1" : "")} style={{ background: 'rgba(238, 39, 55)' }}>
                                <img src={Communities} alt="communities" style={{ height: '100px' }} />
                                <div className="py-3"></div>
                                <h5 className="text-lg">Empowered Agility in Technology</h5>
                                <div className="py-2"></div>
                                <small className="text-md font-light">Adapt to changes and make decisions quickly.</small>
                            </div>
                        </Grow>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Grow in={show} timeout={900}>
                            <div className={"flex flex-col rounded-2xl p-5 h-full duration-500 " + (slide === 3 || slide - 5 === 3 ? "transform-scale-1.1" : "")} style={{ background: 'rgba(0, 170, 19)' }}>
                                <img src={Digital} alt="digital" style={{ height: '100px' }} />
                                <div className="py-3"></div>
                                <h5 className="text-lg">Fearless Creativity in Innovation</h5>
                                <div className="py-2"></div>
                                <small className="text-md font-light">Breaking through the norm by being productive will create a creative solution</small>
                            </div>
                        </Grow>
                    </SwiperSlide>
                    <SwiperSlide>
                        <Grow in={show} timeout={1200}>
                            <div className={"flex flex-col rounded-2xl p-5 h-full duration-500 " + (slide === 4 || slide - 5 === 4 ? "transform-scale-1.1" : "")} style={{ background: 'rgba(0, 174, 214)' }}>
                                <img src={Api} alt="api" style={{ height: '100px' }} />
                                <div className="py-3"></div>
                                <h5 className="text-lg">Creating a Powerful Ecosystem</h5>
                                <div className="py-2"></div>
                                <small className="text-md font-light">Once the ecosystem is established, and reaches the desired scale, the company can provide more extensive service offerings. It can also use to attract the community to create more value.</small>
                            </div>
                        </Grow>
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <div className={"flex flex-col rounded-2xl p-5 h-full duration-500 " + (slide === 0 || slide - 5 === 0 ? "transform-scale-1.1" : "")} style={{ background: 'rgba(238, 39, 55)' }}>
                            <img src={CoreBanking} alt="core-banking" style={{ height: '100px' }} />
                            <div className="py-3"></div>
                            <h5 className="text-lg">CoreBanking</h5>
                            <div className="py-2"></div>
                            <small className="text-xs font-light">We help UMKM to grow together, Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore deserunt a dolore, dignissimos, 
                                deleniti magni ex facilis eaque minima molestias corporis quis id laboriosam sunt et. Modi eligendi commodi adipisci.</small>
                        </div>
                    </SwiperSlide> */}
                </Swiper>
                <div className="absolute left-0 w-full" style={{ bottom: '10%' }}>
                    <div className="grid grid-cols-5 gap-4 text-white w-3/4 mx-auto">
                        <div className="w-full flex justify-center" onMouseEnter={() => handleSlide(0)}>
                            <div className="text-center flex flex-col w-fit-content">
                                <span className="text-lg">Purposeful Growth for Economy-Social</span>
                                <div className={"duration-500 border border-solid border-t-0 border-r-0 border-l-0 border-b-4 border-red-500 mx-auto " + (slide === 0 || slide === 5 ? "w-3/4" : "w-0")}></div>
                            </div>
                        </div>
                        <div className="w-full flex justify-center" onMouseEnter={() => handleSlide(3)}>
                            <div className="text-center flex flex-col w-fit-content">
                                <span className="text-lg">Fearless Creativity in Innovation</span>
                                <div className={"duration-500 border border-solid border-t-0 border-r-0 border-l-0 border-b-4 border-red-500 mx-auto " + (slide === 3 || slide === 8 ? "w-3/4" : "w-0")}></div>
                            </div>
                        </div>
                        <div className="w-full flex justify-center" onMouseEnter={() => handleSlide(1)}>
                            <div className="text-center flex flex-col w-fit-content my-auto">
                                <span className="text-lg">Collaboration</span>
                                <div className={"duration-500 border border-solid border-t-0 border-r-0 border-l-0 border-b-4 border-red-500 mx-auto " + (slide === 1 || slide === 6 ? "w-3/4" : "w-0")}></div>
                            </div>
                        </div>
                        <div className="w-full flex justify-center" onMouseEnter={() => handleSlide(2)}>
                            <div className="text-center flex flex-col w-fit-content">
                                <span className="text-lg">Empowered Agility in Technology</span>
                                <div className={"duration-500 border border-solid border-t-0 border-r-0 border-l-0 border-b-4 border-red-500 mx-auto " + (slide === 2 || slide === 7 ? "w-3/4" : "w-0")}></div>
                            </div>
                        </div>
                        <div className="w-full flex justify-center" onMouseEnter={() => handleSlide(4)}>
                            <div className="text-center flex flex-col w-fit-content">
                                <span className="text-lg">Creating a Powerful Ecosystem</span>
                                <div className={"duration-500 border border-solid border-t-0 border-r-0 border-l-0 border-b-4 border-red-500 mx-auto " + (slide === 4 || slide === 9 ? "w-3/4" : "w-0")}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionBusiness