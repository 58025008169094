import Earth from 'assets/img/Earth.png'
import Slide from '@material-ui/core/Slide'
import Fade from '@material-ui/core/Fade'
import { useState } from 'react'
import { useEffect } from 'react'
import StarBackground from 'assets/img/star_background.png'
import SpaceAdventure from 'assets/img/space_adventure.png'
import GalaxyBackground from 'assets/img/galaxy_background.png'

const SectionAchievment = () => {
    const [show, setShow] = useState()

    useEffect(() => {
        document.addEventListener('scroll', onscroll)

        return () => {
            document.removeEventListener('scroll', onscroll, false)
        }
    }, [])

    const onscroll = () => {
        console.log(window.scrollY)
        const sectionAchievment = document.getElementById('achievment')
        if (window.scrollY >= sectionAchievment?.offsetTop - 200) setShow(true)
    }
    
    return (
        <div className="flex justify-center flex-col py-10 relative galaxy">
            {/* <img src={GalaxyBackground} alt="star_background" className='w-full h-full absolute left-0 top-0 object-cover' /> */}
            {/* <img src={Rocket} alt="rocket" className='absolute right-0 bottom-0 w-1/2' /> */}
            <div className="container text-white text-center mx-auto z-1">
                <h5 className="sm:text-lg md:text-3xl text-lg">We're Minasa</h5>
                <span className="light-italic sm:text-lg md:text-3xl text-lg">The drivers of change for Indonesian's Ecosystem</span>
            </div>
            <div className="py-10"></div>
            <div className="mx-auto flex justify-center self-center relative text-white">
                <Slide in={show} direction="right" timeout={700}>
                    <div className="flex flex-col absolute text-center sm:w-32 md:w-48 w-32" style={{ left: '-200px', top: '20%' }}>
                        <h5 className="sm:text-2xl md:text-3xl lg:text-6xl text-2xl">10+</h5>
                        <div className="py-1"></div>
                        <span className="font-light sm:text-xs md:text-lg text-xs">Social partner in ecosystem</span>
                    </div>
                </Slide>
                <Slide in={show} direction="right" timeout={900}>
                    <div className="flex flex-col absolute text-center sm:w-32 md:w-48 w-32" style={{ left: '-175px', bottom: '20%' }}>
                        <h5 className="sm:text-2xl md:text-3xl lg:text-6xl text-2xl">15+</h5>
                        <div className="py-1"></div>
                        <span className="font-light sm:text-xs md:text-lg text-xs">Financial technology partners & clients</span>
                    </div>
                </Slide>
                <Fade in={show} timeout={500} direction="up">
                    <div className="sm:w-1/2 md:w-3/4 lg:w-full w-1/2 relative">
                        <img src={Earth} alt="earth" className="transition-transform rotating w-full" />
                    </div>
                </Fade>
                <Slide in={show} direction="left" timeout={700}>
                    <div className="flex flex-col absolute text-center sm:w-32 md:w-48 w-32" style={{ right: '-200px', top: '20%' }}>
                        <h5 className="sm:text-2xl md:text-3xl lg:text-6xl text-2xl">2M</h5>
                        <div className="py-1"></div>
                        <span className="font-light sm:text-xs md:text-lg text-xs">Value added to Indonesian Economy in 2021</span>
                    </div>
                </Slide>
                <Slide in={show} direction="left" timeout={900}>
                    <div className="flex flex-col absolute text-center sm:w-32 md:w-48 w-32" style={{ right: '-175px', bottom: '20%' }}>
                        <h5 className="sm:text-2xl md:text-3xl lg:text-6xl text-2xl">100x</h5>
                        <div className="py-1"></div>
                        <span className="font-light sm:text-xs md:text-lg text-xs">Jump in downloads from 2020 to 2021</span>
                    </div>
                </Slide>
            </div>
        </div>
    )
}

export default SectionAchievment