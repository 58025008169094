
import useFetch from 'hooks/useFetch'
import { useEffect } from 'react'
import Fade from '@material-ui/core/Fade'
import { SectionTop, SectionOurValue, SectionBusiness, SectionAchievment, SectionVision, SectionProduct, SectionNews, SectionCoreValue } from './components'
import { useState } from 'react'
export default () => {
    const [fetchResponse, callBack] = useFetch()

    useEffect(() => {
        callBack({ url: '/analitycentries', method: 'POST', data: {
            url: '/home',
            media: 'web'
        } })
    }, [])


    return (
        <div className="">
            <section id="top">
                <SectionTop />
            </section>
            <section id="achievment" className="overflow-hidden">
                <SectionAchievment />
            </section>
            {/* <SectionOurValue /> */}
            {/* <section id="visimisi">
                <SectionVision />
            </section> */}
            {/* <section id="value">
                <SectionValue />
            </section> */}
            {/* <section id="work">
                <SectionWork />
            </section> */}
            <section id="product" className="overflow-hidden">
                <SectionProduct />
            </section>
            <section id="business">
                <SectionBusiness />
            </section>
            <section id="vision">
                <SectionVision />
            </section>
            {/* <SectionNews /> */}
            {/* <div className="sm:px-5 md:px-40 px-5 p-5 flex justify-between bg-gray-100">
                <span>What can we do for you?</span>
                <span className="text-primary">
                    <a href="mailto:support@minasatech.co.id">
                        Let's Discuss
                    </a>
                </span>
            </div> */}
        </div>
    )
}