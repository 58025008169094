const SectionStory = () => {
    return (
        <div className="py-20">
            <div className="sm:p-5 md:px-40 p-5 block">
                <span className="text-5xl font-bold block">Our Story</span>
                <br/>
                <br/>
                <div className="flex">
                    <div className="block w-1/2">
                        <span className="block font-semibold text-xl">Who are we?</span>
                        <br/>
                        <p className="sm:w-3/4 md:w-1/2 w-3/4">
                            Hi! We are Minasa Finteknologi Syariah — young generations who passionate engineers of user experience, Banking, Finance, many more. 
                            Building things people want makes us happy! 
                        </p>
                    </div>
                    <div className="block w-1/2">
                        <span className="block font-semibold text-xl">What’s up with the name?</span>
                        <br/>
                        <p className="sm:w-3/4 md:w-1/2 w-3/4">
                            Minasa means platform
                            Fintekologi means collaboration of financial and technology
                            Syariah means we are specialized in sharia environment for financial and social
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionStory