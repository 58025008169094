import useFetch from "hooks/useFetch"
import { serialize } from "mixin"
import { useState } from "react"
import { useLocation } from "react-router"
import Success from './components/Success'
import Stage1 from './components/Stage1'
import Stage2 from './components/Stage2'
import Stage3 from './components/Stage3'
import Stage4 from './components/Stage4'
import Stage5 from './components/Stage5'
import Stage6 from './components/Stage6'
import Stage7 from './components/Stage7'

const SectionPersonalInfo = () => {
    const [fetchResponse, callBack] = useFetch()
    const [image, setImage] = useState()
    const [errorImage, setErrorImage] = useState()
    const [open, setOpen] = useState(false)
    const [selectedStage, setSelectedStage] = useState(1)
    const [stage1Data, setStage1Data] = useState({})
    const [stage2Data, setStage2Data] = useState({})
    const [stage3Data, setStage3Data] = useState({})
    const [stage4Data, setStage4Data] = useState({})
    const [stage5Data, setStage5Data] = useState({})
    const [stage6Data, setStage6Data] = useState({})
    const [stage7Data, setStage7Data] = useState({})

    const location = useLocation()
    const query = new URLSearchParams(location.search)
    const job = query.get('job')

    const handleSubmit = async e => {
        e?.preventDefault()
        if (!image) return setErrorImage('foto diperlukan')
        var obj = serialize('save')
        obj.image = image
        obj.job_request = job
        const res = await callBack({ url: '/applicants/create', method: 'POST', data: obj })
        if (res.message === "Success") {
            setOpen(true)
        }
        console.log(obj)
    }

    const handleOnSubmit = async (e) => {
        e?.preventDefault()
        var obj = serialize('save')
        var obj = {
            ...stage1Data,
            ...stage2Data,
            ...stage3Data,
            ...stage4Data,
            ...stage5Data,
            ...stage6Data,
            ...obj
        }

        obj.job_request = job
        
        const res = await callBack({ url: '/applicants/create', method: 'POST', data: obj })
        if (res.message === "Success") {
            setOpen(true)
        }
        
        console.log(obj)
    }

    const handleChangeImage = e => {
        var file = e.target.files[0]
        console.log(file.size)
        if (!file) return false
        if (file.size > 200000) return false
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var result = reader.result
            setImage(result)
        };
    }

    const handleOnNext = stage => {
        var obj = serialize('save')
        console.log(obj)
        if (selectedStage === 1) {
            setStage1Data(obj)
        } else if (selectedStage === 2) {
            setStage2Data(obj)
        } else if (selectedStage === 3) {
            setStage3Data(obj)
        } else if (selectedStage === 4) {
            setStage4Data(obj)
        } else if (selectedStage === 5) {
            setStage5Data(obj)
        } else if (selectedStage === 6) {
            setStage6Data(obj)
        } else if (selectedStage === 7) {
            setStage7Data(obj)
        }
        setSelectedStage(selectedStage + 1)
    }

    const handleOnBack = stage => {
        var obj = serialize('save')
        console.log(obj)
        if (selectedStage === 1) {
            setStage1Data(obj)
        } else if (selectedStage === 2) {
            setStage2Data(obj)
        } else if (selectedStage === 3) {
            setStage3Data(obj)
        } else if (selectedStage === 4) {
            setStage4Data(obj)
        } else if (selectedStage === 5) {
            setStage5Data(obj)
        } else if (selectedStage === 6) {
            setStage6Data(obj)
        } else if (selectedStage === 7) {
            setStage7Data(obj)
        }
        setSelectedStage(selectedStage - 1)
    }

    return (
        <div className="container mx-auto py-10">
            <Success checked={open} closePopup={() => setOpen(false)} />
            {/* <span className="block font-bold text-xl text-gray-500">Personal Information</span> */}
            <form id="save" className="w-full" onSubmit={handleSubmit}>
                {
                    selectedStage === 1 ?
                        <Stage1 data={stage1Data} setData={setStage1Data} onNext={handleOnNext} /> :
                            selectedStage === 2 ? 
                                <Stage2 data={stage2Data} setData={setStage2Data} onNext={handleOnNext} onBack={handleOnBack} /> : 
                                    selectedStage === 3 ? 
                                        <Stage3 data={stage3Data} setData={setStage3Data} onNext={handleOnNext} onBack={handleOnBack} /> : 
                                            selectedStage === 4 ? 
                                                <Stage4 data={stage4Data} setData={setStage4Data} onNext={handleOnNext} onBack={handleOnBack} /> : 
                                                    selectedStage === 5 ?
                                                        <Stage5 data={stage5Data} setData={setStage5Data} onNext={handleOnNext} onBack={handleOnBack} /> : 
                                                            selectedStage === 6 ? 
                                                                <Stage6 data={stage6Data} setData={setStage6Data} onNext={handleOnNext} onBack={handleOnBack} /> : 
                                                                    selectedStage === 7 ?
                                                                        <Stage7 data={stage7Data} setData={setStage7Data} onSubmit={handleOnSubmit} onBack={handleOnBack} /> : null
                }
                {/* <div className="flex">
                    <div className="block w-full">
                        <label className="block mb-2 text-gray-400 font-semibold">FIRST NAME</label>
                        <input type="text" name="first_name" className="w-full p-2 border border-solid border-green-primary" />
                    </div>
                    <div className="px-2"></div>
                    <div className="block w-full">
                        <label className="block mb-2 text-gray-400 font-semibold">LAST NAME</label>
                        <input type="text" name="last_name" className="w-full p-2 border border-solid border-green-primary" />
                    </div>
                </div>
                <div className="py-3"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400 font-semibold">ADDRESS</label>
                    <input type="text" name="address" className="w-full p-2 border border-solid border-green-primary" />
                </div>
                <div className="py-3"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400 font-semibold">GENDER</label>
                    <select name="gender" className="w-full p-2 border border-solid border-green-primary">
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                    </select>
                </div>
                <div className="py-3"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400 font-semibold">BIRTH DATE</label>
                    <input type="date" name="birth_date" className="w-full p-2 border border-solid border-green-primary" />
                </div>
                <div className="py-3"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400 font-semibold">PHONE</label>
                    <input type="text" name="phone" className="w-full p-2 border border-solid border-green-primary" />
                </div>
                
                <div className="py-3"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400 font-semibold">Last Education</label>
                    <input type="text" name="last_education" className="w-full p-2 border border-solid border-green-primary" />
                </div>

                <div className="py-3"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400 font-semibold">Major</label>
                    <input type="text" name="major" className="w-full p-2 border border-solid border-green-primary" />
                </div>

                <div className="py-3"></div>
                <div className="block w-full">
                    <label className="block mb-2 text-gray-400 font-semibold">Upload Photo (MAXIMUM 200 kb)</label>
                    <div className="w-1/3 border border-solid border-green-primary rounded-lg relative" style={{ height: '200px' }}>
                        <input type="file" className="absolute top-0 left-0 opacity-0 w-full h-full" accept="image/*" onChange={handleChangeImage} />
                        {
                            image ? 
                                <img src={image} alt="" className="w-full h-full object-cover" /> : null
                        }
                    </div>
                    <span className="text-red-600">{errorImage}</span>
                </div>
                <div className="py-3"></div>
                <button className="p-2 bg-green-primary text-white w-full rounded-lg" onClick={handleSubmit}>Continue</button> */}
            </form>
        </div>
    )
}

export default SectionPersonalInfo