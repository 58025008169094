import Slide from '@material-ui/core/Slide'
import Target from 'assets/svg/target.svg'
import Brain from 'assets/svg/brain.svg'
import Idea from 'assets/svg/idea.svg'
import Give from 'assets/svg/give.svg'
import Users from 'assets/svg/users.svg'
import Good from 'assets/svg/good.svg'
const SectionCoreValue = () => {
    const data = [
        {
            name: 'Dedication',
            desc: 'Devote to a specific purpose',
            background: '#4473C5',
            image: Target
        },
        {
            name: 'Integrity',
            desc: 'The following of moral or ethical principles',
            background: '#ED7D31',
            image: Idea

        },
        {
            name: 'Vibrant',
            desc: 'Having a great life, activity, and career',
            background: '#A5A5A5',
            image: Brain
        },
        {
            name: 'Inspire',
            desc: 'To influence, motivate, or produce a feeling',
            background: '#FFC001',
            image: Give
        },
        {
            name: 'Neighborly',
            desc: 'Kind and friendly, as in between neighbors',
            background: '#ED7D31',
            image: Users
        },
        {
            name: 'Excellence',
            desc: 'Someone or something of high quality',
            background: '#4473C5',
            image: Good
        }
    ]

    return (
        <section>
            <div className="w-full h-screen planet relative overflow-hidden py-10">
                <div className="flex flex-col text-white text-center">
                    <h5 className="text-5xl">Here's our core value</h5>
                    <div className="py-2"></div>
                </div>
                <div className="container mx-auto">
                    <div className="grid grid-cols-5 gap-4 h-full">
                        <div className="relative h-full col-span-4">
                            <div className="grid grid-cols-6 gap-10 absolute relative w-full" style={{ top: '28%' }}>
                                {
                                    data?.map((e, n) => {
                                        return (
                                            <Slide in={true} direction='up' timeout={n === 0 ? 300 : (300 + n * 200)}>
                                                <div className="w-full core-shadow p-2 flex flex-col rounded-t-full relative" style={{ background: e?.background, height: '1000px', marginTop: (n === 0 ? '300' : 300 - (n * 50)) + 'px' }}>
                                                    <div className="w-full flex flex-col absolute text-center text-white" style={{ bottom: '102%', left: '0' }}>
                                                        <Slide in={true} direction='up' timeout={500}>
                                                            <div className="flex flex-col">
                                                                <span className="text-2xl font-bold">{e?.name}</span>
                                                                <div className="py-1"></div>
                                                                <span>{e?.desc}</span>
                                                                <div className="py-2"></div>
                                                            </div>
                                                        </Slide>
                                                        <Slide in={true} direction='up' timeout={400}>
                                                            <div className="w-2 h-48 bg-white mx-auto rounded-full glowing-shadow"></div>
                                                        </Slide>
                                                    </div>
                                                    <div className="bg-white rounded-full w-full h-1/6 mx-auto flex justify-center">
                                                        <h5 className="text-6xl self-center" style={{ color: e?.background }}>{e?.name?.[0]}</h5>
                                                    </div>
                                                    <div className="py-3"></div>
                                                    <img src={e?.image} alt="" width="75px" className="mx-auto" />
                                                </div>
                                            </Slide>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className="ml-auto h-3/5 grid grid-rows-6 text-white">
                                <span className='tb-rl m-auto text-lg'>Dedication</span>
                                <span className='tb-rl m-auto text-lg'>Integrity</span>
                                <span className='tb-rl m-auto text-lg'>Vibrant</span>
                                <span className='tb-rl m-auto text-lg'>Inspire</span>
                                <span className='tb-rl m-auto text-lg'>Neighborly</span>
                                <span className='tb-rl m-auto text-lg'>Excellence</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SectionCoreValue