import { useState, useEffect, useCallback } from 'react'
// import { useDispatch } from 'react-redux'
// import { backdropConstants } from '../_constants'
// import { useSnackbar } from 'notistack'

export default function useFetch(props) {
    const [responseData, setResponseData] = useState({})
    // const dispatch = useDispatch()
    // const { enqueueSnackbar } = useSnackbar()

    async function fetchData(props) {
        var response
        if (!props?.url) return null
        try {
            // dispatch({ type: backdropConstants.CHANGE_LABEL, payload: 'Ini Loading' })
            // dispatch({ type: backdropConstants.CHANGE_LOADING, payload: true })
            response = await global.$baseApi(props)
            console.log('panggil ini', response)
            setResponseData(response)
        } catch (err) {
            console.log(err, props.url)
            // dispatch({ type: backdropConstants.CHANGE_LOADING, payload: false })
            // enqueueSnackbar(err.message, { variant: 'error' })
        } finally {
            console.log('finally here')
            // dispatch({ type: backdropConstants.CHANGE_LOADING, payload: false })
        }
        return response
    }

    const callBack = useCallback(async (props) => {
        const response = await fetchData(props)
        return response
    });

    // useEffect(() => {
    //     fetchData(props)
    // }, [props?.url])

    // return responseData
    return [responseData, callBack]
}