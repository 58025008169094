import Handojo from 'assets/img/Handojo.png'
import Agus from 'assets/img/Agus.png'
import Muliawan from 'assets/img/Muliawan.png'
import Dini from 'assets/img/Dini.png'
import Alvian from 'assets/img/Alvian.png'
import Rizky from 'assets/img/Rizki.png'
import Teams from 'assets/img/Group 3715@2x.png'
import { ChevronRight, ChevronLeft } from '@material-ui/icons'
import { IconButton } from '@material-ui/core'
import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useRef } from 'react'
const SectionTeam = () => {
    const [size, setSize] = useState('400px')
    const [slide, setSlide] = useState(0)
    const swiperRef = useRef()

    const onSlideChange = (e) => {
        console.log(e?.activeIndex)
        setSlide(e?.activeIndex)
    }

    return (
        <div className="py-20 w-full planet">
            {/* <div className="container mx-auto flex flex-col text-white py-10">
                <div className="text-center w-1/2 mx-auto">
                    <h5 className="text-3xl">Meet Our Team</h5>
                    <div className="py-1"></div>
                    <p>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. 
                        Nam vel fugiat voluptas eveniet qui aliquam rerum earum, porro ipsa fugit voluptate numquam blanditiis nisi hic perferendis. Id fugit aliquid nostrum?
                    </p>
                </div>
            </div> */}
            <div className="container mx-auto grid grid-cols-2 gap-20">
                <div className="w-full flex flex-col">
                    <div className="grid grid-cols-3 gap-4">
                        <div className="flex flex-col text-center text-white">
                            <img width="100%" src={Handojo} alt="" className='mt-auto hover:rotateZ transition-all' />
                            <div className="py-2"></div>
                            <span className="text-lg">Handojo Sutjipto</span>
                            <span className="text-primary">Starter Guru</span>
                        </div>
                        <div className="flex flex-col text-center text-white">
                            <img width="100%" src={Agus} alt="" className='mt-auto hover:rotateZ transition-all' />
                            <div className="py-2"></div>
                            <span className="text-lg">Agus Tri Widodo</span>
                            <span className="text-primary">Hustler</span>
                        </div>
                        <div className="flex flex-col text-center text-white">
                            <img width="100%" src={Muliawan} alt="" className='mt-auto hover:rotateZ transition-all' />
                            <div className="py-2"></div>
                            <span className="text-lg">Muliawan Wijaya</span>
                            <span className="text-primary">Thought Magician</span>
                        </div>
                    </div>
                    <div className="py-3"></div>
                    <div className="grid grid-cols-3 gap-4">
                        <div></div>
                        <div className="flex flex-col text-center text-white">
                            <img width="100%" src={Dini} alt="" className='mt-auto hover:rotateZ transition-all' />
                            <div className="py-2"></div>
                            <span className="text-lg">Amelia Rakhmadini</span>
                            <span className="text-primary">Tomorrow Marker</span>
                        </div>
                        <div className="flex flex-col text-center text-white">
                            <img width="100%" src={Rizky} alt="" className='mt-auto hover:rotateZ transition-all' />
                            <div className="py-2"></div>
                            <span className="text-lg">Rizky Dewanto</span>
                            <span className="text-primary">Platform Sier</span>
                        </div>
                    </div>
                    <div className="py-3"></div>
                    <div className="grid grid-cols-3 gap-4">
                        <div className='col-span-2'></div>
                        <div className="flex flex-col text-center text-white">
                            <img width="100%" src={Alvian} alt="" className='mt-auto hover:rotateZ transition-all' />
                            <div className="py-2"></div>
                            <span className="text-lg">Alvian Haslim</span>
                            <span className="text-primary">Strategic Alchemist</span>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col justify-center text-white w-3/4'>
                    <h5 className="text-3xl">Meet Our Team</h5>
                    <div className="py-1"></div>
                    <p>
                        With over 100 years of combined experienced, we've got a well-seasoned team at the helm
                    </p>
                </div>
            </div>
        </div>
        // <div className="py-20">
        //     <div className="block text-center">
        //         <span className="font-bold text-4xl">Meet Our Team</span>
        //         <p className="mt-5 text-gray-500 text-lg">
        //             These people are the reasons for our success and stainless reputation. <br/> 
        //             They have years of experience and absolute dedication to what they do
        //         </p>
        //     </div>
        //     <div className="sm:hidden md:block hidden">
        //         <img src={Teams} alt="" />
        //     </div>
        //     <div className="sm:block md:hidden block px-5 justify-center relative sm:h-full sm:h-full md:h-96 h-full sm:m-0 md:mb-40 m-0">
        //         <div style={{ width: '300px', height: '300px', left: '39px' }} className="rounded-full bg-green-primary absolute"></div>
        //         <div style={{ width: '250px', height: '250px', right: '39px', top: '42px' }} className="rounded-full bg-green-primary absolute"></div>
        //         <div className="block text-center sm:relative md:absolute relative" style={{ top: 0, left: 0 }}>
        //             <img width="100%" src={Handojo} alt=""/>
        //             <span className="block font-bold text-lg">Handojo Sutjipto</span>
        //             <span className="block text-gray-600">Kick Starter Guru</span>
        //         </div>
        //         <div className="block text-center sm:inherit md:absolute inherit" style={{ top: '38px', left: '16%' }}>
        //             <img width="100%" src={Agus} alt=""/>
        //             <span className="block font-bold text-lg">Agus Tri Widodo</span>
        //             <span className="block text-gray-600">Hustler</span>
        //         </div>
        //         <div className="block text-center sm:inherit md:absolute inherit" style={{ left: '30%', top: '7px' }}>
        //             <img width="100%" src={Muliawan} alt=""/>
        //             <span className="block font-bold text-lg">Muliawan Wijaya</span>
        //             <span className="block text-gray-600">Thought Magician</span>
        //         </div>
        //         <div className="block text-center sm:inherit md:absolute inherit z-2" style={{ left: '42%', top: '-22px' }}>
        //             <img width="100%" src={Dini} alt=""/>
        //             <span className="block font-bold text-lg">Amelia Rakhmadini</span>
        //             <span className="block text-gray-600">Tomorrow Marker</span>
        //         </div>
        //         <div className="block text-center sm:inherit md:absolute inherit z-1" style={{ left: '61%', top: '22px' }}>
        //             <img width="100%" src={Alvian} alt=""/>
        //             <span className="block font-bold text-lg">Alvian Haslim</span>
        //             <span className="block text-gray-600">Strategic Alchemist</span>
        //         </div>
        //         <div className="block text-center sm:inherit md:absolute inherit" style={{ left: '75%', top: '9px' }}>
        //             <img width="100%" src={Rizky} alt=""/>
        //             <span className="block font-bold text-lg">Rizky Dewanto</span>
        //             <span className="block text-gray-600">Platform Sier</span>
        //         </div>
        //     </div>
        // </div>
    )
}

export default SectionTeam