import Grow from '@material-ui/core/Grow'
import Slide from '@material-ui/core/Slide'
import Button from '@material-ui/core/Button'
import OurJob from 'assets/img/OurValue.png'
import Fade from '@material-ui/core/Fade'
import YellowRocket from 'assets/img/yellow_rocket.png'
import Planet from 'assets/img/planet.png'
import CartoonGalaxyBackground from 'assets/img/cartoon_galaxy_background.png'
import TopHeader from './components/topHeader'
import { useState, useEffect } from 'react'

const SectionTop = () => {
    const [show, setShow] = useState(true)

    const showHeader = () => {
        console.log('show header', window.pageYOffset)
        var top = window.pageYOffset
        setShow(top < 200 ? true : false)
        console.log(top < 200 ? true : false)
    }

    useEffect(() => {
        showHeader()
        document.addEventListener('scroll', onScroll)
        return () => {
            document.removeEventListener('scroll', onscroll, false)
        }
    }, [])

    const onScroll = () => {
        showHeader()
    }

    const handleScrollSpy = () => {
        var work = document.getElementById('team')
        window.scrollTo({
            top: work.offsetTop,
            behavior: 'smooth'
        });
    }

    return (
        <div className="h-screen w-full flex justify-center relative overflow-hidden planet">
            {
                show ?
                    <Fade in={true} timeout={500}>
                        <TopHeader />
                    </Fade> : null
            }            
            <Fade in={true} timeout={500}>
                <img src={CartoonGalaxyBackground} alt="planet" className='absolute left-0 bottom-0 w-full' />
            </Fade>
            <div className="container self-center text-white h-1/2">
                <div className="flex flex-col m-auto text-center relative w-3/5 h-full">
                    <img src={YellowRocket} alt="yellow_rocket" className='absolute w-1/5 rocket' />
                    <div className="flex flex-col justify-between z-1 h-full">
                        <Slide direction="down" in={true} timeout={500} mountOnEnter unmountOnExit>
                            <div className="flex flex-col">
                                <h2 className="text-5xl">{('Are you still curious about Minasa').toUpperCase()}</h2>
                                <div className="py-1"></div>
                                <h2 className="text-5xl">{('and would like to know deeper?').toUpperCase()}</h2>
                                {/* <div className="py-2"></div>
                                <span className="text-5xl light-italic">Change for better!</span> */}
                                <div className="py-10"></div>
                                <Slide direction="up" in={true} timeout={700} mountOnEnter unmountOnExit>
                                    <div className="w-1/2 text-center mx-auto text-primary-light">
                                        <h5 className='text-lg'>This company consist of resources who are expert in their respective fields, and are collaborative to work together in developing minasa. buat di bawah meet our team</h5>
                                    </div>
                                </Slide>
                                <div className="py-5"></div>
                            </div>
                        </Slide>
                        <div className='w-1/5 mx-auto rounded-full overflow-hidden'>
                            <Button variant="contained" centerRipple fullWidth color="primary" onClick={handleScrollSpy}>
                                <span>Explore more</span>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionTop