import useFetch from 'hooks/useFetch'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import './styles.scss'
import Detail from './components/detail'
import Empty from 'assets/img/Empty.gif'
const SectionCarrer = () => {
    const [fetchResponse, callBack] = useFetch()
    const [data, setData] = useState([])
    const history = useHistory()
    const [open, setOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState({})

    useEffect(async () => {
        const res = await callBack({ url: '/filter/career_required/0/10', method: 'POST' })
        setData(res?.data)
    }, [])

    return (
        <div className="sm:px-5 md:px-40 px-5 py-10">
            <Detail checked={open} data={selectedItem} closePopup={() => setOpen(false)} />
            {
                data && data.length > 0 ?
                    <div className="job-gallery sm:block md:grid block">
                        {
                                data?.map(item => {
                                    return (
                                        <div className="bg-white shadow-xl block p-3 h-fit-content sm:mb-2 md:m-0 mb-2">
                                            <span className="block text-2xl text-primary">{item.title}</span>
                                            <br/>
                                            <div dangerouslySetInnerHTML={{ __html: item?.description }} className="overflow-hidden" style={{ maxHeight: '100px' }}></div>
                                            {/* <p>
                                                {item.description}
                                            </p> */}
                                            <span className="text-primary cursor-pointer" onClick={() => {
                                                setSelectedItem(item)
                                                setOpen(true)
                                            }}>See more...</span>
                                            <br/>
                                            <br/>
                                            <div className="flex justify-center cursor-pointer" onClick={() => history.push('/applicant?job=' + item.title)}>
                                                <span className="text-primary font-bold">APPLY FOR THIS JOB POSITION</span>
                                            </div>
                                        </div>
                                    )
                                })
                        }
                    </div> : 
                        <div className="flex w-full justify-center">
                            <img src={Empty} alt="" />
                        </div>
            }
        </div>
    )
}

export default SectionCarrer