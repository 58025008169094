import Logo from 'assets/img/Logo Minasa black.png'
import useFetch from 'hooks/useFetch'
import { useEffect } from 'react'

const PrivacyPolicy = () => {
    const [fetchResponse, callBack] = useFetch()
    useEffect(() => {
        callBack({ url: '/analitycentries', method: 'POST', data: {
            url: '/privacy-policy',
            media: 'web'
        } })
    }, [])
    return (
        <div className="block container mx-auto">
            <div className="flex bg-primary">
                <img src={Logo} alt="" />
            </div>
            <div className="py-2"></div>
            <p>
                Platform Digital Koperasi DDS memahami bahwa data Anda sangat penting, terutama data transaksi koperasi, baik antar anggota ke koperasi maupun antara anggota dan anggota koperasi lainnya. 
                Dengan menggunakan Platform Digital Koperasi DDS dan Anggota memiliki hak privasi tinggi agar data yang terdapat di aplikasi tidak dipergunakan untuk kepentingan-kepentingan diluar kepentingan masing-masing koperasi.
                Anggota yang telah menggunakan Platform Digital Koperasi DDS telah melakukan Perjanjian Kerjasama dengan pihak Koperasi DDS yang salah satu pasal pentingnya adalah masalah kebijakan privasi dan batasan akses data yang diperbolehkan, yaitu sebatas kepentingan pendampingan, perbaikan dan juga pengembangan fitur, yang sebelumnya telah disetujui dan diketahui oleh Koperasi pengguna.
            </p>
            <br />
            <p>
                Platform Digital Koperasi DDS mendapatkan persetujuan untuk beberapa aktivitas terbatas sebagai berikut :
            </p>
            <ul>
                <li>
                    * Platform DDS memiliki hak untuk mengumpulkan data pengguna melalui proses registrasi, log aktivitas, informasi aktivitas user, cookies, akses perangkat (perangkat yang digunakan), dan informasi browser.
                </li>
                <li>
                    * Platform DDS berhak menggunakan data pengguna TERBATAS untuk keperluan komunikasi antar pengguna, sistem pengingat (notifikasi) dan publikasi informasi sesuai dengan proposinya dengan tetap mengedepankan kenyamanan pengguna.
                </li>
                <li>
                    * Platform DDS berhak menggunakan data informasi pengguna TERBATAS untuk keperluan promosi, notifikasi, broadcast, dan informasi publik lainnya yang masih berkaitan dengan informasi Platform DDS
                </li>
                <li>
                    * Platform DDS berhak menghapus data yang dianggap tidak sesuai, tidak sah dan melanggar peraturan dan kebijakan Platform DDS juga peraturan hukum Negera Republik Indonesia. Hak cipta gambar produk dan konten tetap milik mitra pengguna.
                </li>
                <li>
                    * Demikian kebijakan privasi ini dibuat yang bertujuan untuk kenyamanan pengguna Platform DDS
                </li>
            </ul>

        </div>
    )
}

export default PrivacyPolicy